import React from 'react'
import PropTypes from 'prop-types'
import Relation from '../Relation'
import RadioGroup from '../../formElements/RadioGroup'
import TextInput from '../../formElements/TextInput'

function RadioGroupWithTextInput (props) {
  const {
    className,
    data,
    radioGroupProps,
    textInputProps,
    displayValues,
    mapValue,
    visibility,
    onUpdate
  } = props
  const classNameList = ['radio-group-relation']
  if (className) {
    classNameList.push(className)
  }

  return (
    <Relation
      className={classNameList.join(' ')}
      data={data}
      trigger={RadioGroup}
      triggerProps={radioGroupProps}
      projection={TextInput}
      projectionProps={textInputProps}
      condition={({ value, projection }) => {
        if (displayValues === true || displayValues.includes(value)) {
          return projection
        }

        return false
      }}
      mapValue={mapValue}
      visibility={visibility}
      onUpdate={onUpdate}
    />
  )
}

RadioGroupWithTextInput.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  /** See RadioGroup's "PROPS & METHODS" */
  radioGroupProps: PropTypes.object,
  /** See TextInput's "PROPS & METHODS" */
  textInputProps: PropTypes.object,
  /** Show TextInput if displayValues (boolean) is equal true or one of value in displayValues (array) */
  displayValues: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool
  ]),
  mapValue: PropTypes.func,
  visibility: PropTypes.bool,
  onUpdate: PropTypes.func
}

RadioGroupWithTextInput.defaultProps = {
  displayValues: [true]
}

export default RadioGroupWithTextInput
