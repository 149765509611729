import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

function CheckboxGroup (props) {
  const { nameList, readOnly, onUpdate } = props
  const components = nameList.map(n => {
    const { name, label, value } = n
    function update (ev) {
      const { checked } = ev.target
      onUpdate({ attr: name, value: checked })
    }

    const input = readOnly
      ? undefined
      : (<input type='checkbox' name={name} defaultChecked={value} onClick={update} />)
    const readOnlyValue = typeof value === 'boolean' ? value.toString() : value
    const readOnlyLabel = readOnly
      ? (<pre>{readOnlyValue}</pre>)
      : undefined
    const className = readOnly ? undefined : 'ckbox'
    return (
      <label key={`${name}-checkbox-label-key`} className={className}>
        {input}
        <span>{label}</span>
        {readOnlyLabel}
      </label>
    )
  })

  return (
    <div className='form-group checkbox-group'>
      {components}
    </div>
  )
}

CheckboxGroup.propTypes = {
  nameList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.bool
    })
  ).isRequired,
  readOnly: PropTypes.bool,
  onUpdate: PropTypes.func

}

CheckboxGroup.defaultProps = {
  nameList: [],
  readOnly: false,
  onUpdate: ({ attr, value }) => {
    console.log('CheckboxGroup', 'onUpdate', 'attr', attr, 'value', value)
  }
}

export default CheckboxGroup
