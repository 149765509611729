// https://github.com/react-bootstrap-table/react-bootstrap-table2/blob/master/packages/react-bootstrap-table2/src/utils.js
// https://www.npmjs.com/package/jsonpath-plus
// https://github.com/JSONPath-Plus/JSONPath
import { JSONPath } from 'jsonpath-plus'

function convertBooleanToString (value) {
  if (value === true) {
    return 'true'
  } else if (value === false) {
    return 'false'
  }

  return value
}

function convertStringToBoolean (value) {
  if (value === 'true') {
    return true
  } else if (value === 'false') {
    return false
  }

  return value
}

function equalOrIncludes (value, search) {
  const searchList = typeof search === 'string' ? search.split('|') : [convertBooleanToString(search)]

  if (Array.isArray(value)) {
    return value.some(v => searchList.includes(convertBooleanToString(v)))
  }

  return searchList.includes(convertBooleanToString(value))
}

export function isEmpty (val, excludeValues = []) {
  if (excludeValues.includes(val)) {
    return false
  }
  if (Array.isArray(val)) {
    return val.length === 0
  } else if (val && typeof val === 'object') {
    return Object.keys(val).length === 0
  }

  return !Boolean(val)
}

// abc[type=simple]
const hashInAryReg = /(\w+)\[([\w:]+)(?:=((?:\w|\|)+))?\](?:\[(\*|\d*)\])?$/

export function get (target, field = '', separator = '.') {
  if (!target || typeof field !== 'string') {
    return
  }

  if (field.startsWith('$.')) {
    return JSONPath(field, target)
  }

  const directGet = target[field]
  if (directGet !== undefined && directGet !== null) {
    return directGet
  }

  const pathArray = field.split(separator)
  let result
  try {
    result = pathArray.reduce((curr, path) => {
      const match = hashInAryReg.exec(path)

      if (Array.isArray(match)) {
        const [fullPath, itemPath, itemAttr, itemAttrValue, arrayIndex] = match
        if (itemAttr) {
          if (itemAttrValue) {
            if (arrayIndex) {
              // a[b=c][*]
              const list = curr[itemPath].filter(c => equalOrIncludes(get(c, itemAttr, ':'), convertBooleanToString(itemAttrValue)))
              if (isNaN(arrayIndex)) {
                return list
              } else {
                return list[arrayIndex]
              }
            }

            // a[b=c]
            return curr[itemPath].find(c => equalOrIncludes(get(c, itemAttr, ':'), convertBooleanToString(itemAttrValue)))
          } else {
            // a[b]
            if (arrayIndex) {
              return curr[itemPath].filter(c => get(c, itemAttr, ':') !== undefined)
            } else if (isNaN(itemAttr)) {
              return curr[itemPath].find(c => get(c, itemAttr, ':') !== undefined)
            } else { // a[__number__]
              return curr[itemPath][itemAttr]
            }
          }
        }
      }

      return curr[path]
    }, target)
  } catch (e) {}
  return result
}

export function set (target, field = '', value) {
  const pathArray= field.split('.')
  pathArray.reduce((val, b, level) => {
    const match = hashInAryReg.exec(b)
    const isLastItem = level === pathArray.length - 1

    if (Array.isArray(match)) {
      const [fullPath, itemPath, itemAttr, itemAttrValue, arrayIndex] = match
      if (itemPath) {
        if (val[itemPath] === undefined) {
          val[itemPath] = []
        }
        if (itemAttr) {
          // a.itemPath[itemAttr=itemAttrValue], ex.: a.b[summary=details]
          // a.itemPath[itemAttr], ex.: a.b[summary]
          if (itemAttrValue || isNaN(itemAttr)) {
            // set default itemAttrValue to first one in the list if value not found, a.b[summary=first|second]
            const defaultItemAttrValue = itemAttrValue ? convertStringToBoolean(itemAttrValue.split('|')[0]) : true
            let index = itemAttrValue
              ? val[itemPath].findIndex(v => v && equalOrIncludes(get(v, itemAttr, ':'), convertStringToBoolean(itemAttrValue)))
              : val[itemPath].findIndex(v => v && get(v, itemAttr, ':') !== undefined)

            if (index === -1) { // New Item not in list
              index = val[itemPath].length
              val[itemPath][index] = {}
              val[itemPath][index][itemAttr] = defaultItemAttrValue
            } else if (!isNaN(arrayIndex)) { // a.b[summary][1]
              let loopIndex = arrayIndex
              while (loopIndex > 0 && index > -1) {
                loopIndex--
                index = itemAttrValue
                  ? val[itemPath].findIndex((v, i) => {
                      return v
                        && get(v, itemAttr, ':') === defaultItemAttrValue
                        && i > index
                    })
                  : val[itemPath].findIndex((v, i) => {
                      return v
                        && get(v, itemAttr, ':') !== undefined
                        && i > index
                    })
              }
              if (index === -1 || !val[itemPath][index]) { // New Item not in list
                index = val[itemPath].length
                val[itemPath][index] = {}
                val[itemPath][index][itemAttr] = defaultItemAttrValue
              }
            }
            if (isLastItem) {
              if (value === undefined) {
                // use != to ignore variable type
                val[itemPath] = val[itemPath].filter((v, i) => i != index) // eslint-disable-line eqeqeq
              } else {
                val[itemPath][index] = value
              }
            }

            return val[itemPath][index]
          } else { // a.itemPath[itemAttr], ex.: a.b[1]
            if (isLastItem) {
              if (value === undefined) {
                // use != to ignore variable type
                val[itemPath] = val[itemPath].filter((v, i) => i != itemAttr) // eslint-disable-line eqeqeq
              } else {
                val[itemPath][itemAttr] = value
              }
            } else if (val[itemPath][itemAttr] === undefined) {
              val[itemPath][itemAttr] = {}
            }

            return val[itemPath][itemAttr]
          }
        }
      }
    } else { // a.itemPath
      if (isLastItem) {
        if (value === undefined) {
          delete val[b]
        } else {
          val[b] = value
        }
      } else if (val[b] === undefined) {
        val[b] = {}
      }

      return val[b]
    }

    return undefined
  }, target)
}
