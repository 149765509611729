import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ButtonGroup from '../../generic/ButtonGroup'
import ProgressBar from '../../generic/ProgressBar'
import Tabs from '../../generic/Tabs'
import EditPopup from './EditPopup'

import './style.css'

const STYLE_TABS = 'tabs'
const STYLE_PROGRESS_BAR = 'progressBar'

function getTabsOptions (c, setPageIndex) {
  return c.map((c, i) => {
    const { menuTitle } = c
    return {
      label: menuTitle,
      onClick: () => { setPageIndex(i) }
    }
  })
}

function PageGenerator (props) {
  const {
    title,
    subTitle,
    configuration,
    editable,
    progressBarProps,
    menuStyle,
    holderClassName,
    returnBtnProps,
    backBtnProps,
    continueBtnProps,
    completeBtnProps,
    onProgressBarClick,
    pageIndex: defaultPageIndex,
    hideButtonFooter,
    buttonGroupClassName
  } = props
  const [pageIndex, setPageIndex] = useState(defaultPageIndex)
  const [conf, setConf] = useState(configuration)
  const [tabsOptions, setTabsOptions] = useState(getTabsOptions(conf, setPageIndex))
  const [currentPage, setCurrentPage] = useState(conf[pageIndex])

  useEffect(() => {
    setConf(configuration)
  }, [configuration])

  useEffect(() => {
    if (Array.isArray(conf)) {
      setTabsOptions(getTabsOptions(conf, setPageIndex))
      if (pageIndex < conf.length) {
        setCurrentPage(conf[pageIndex])
      } else {
        setPageIndex(conf.length - 1)
      }
    }
  }, [conf])

  useEffect(() => {
    setPageIndex(defaultPageIndex)
  }, [defaultPageIndex])

  useEffect(() => {
    setCurrentPage(conf[pageIndex])
  }, [pageIndex])

  const { pageTitle = '', content = '' } = currentPage || {}
  const showTabsOrProgressBar = tabsOptions.length > 1
  const tabs = showTabsOrProgressBar && menuStyle === STYLE_TABS ? (
    <Tabs
      options={tabsOptions}
      pageIndex={pageIndex}
    />
  ) : undefined
  const stepLabels = conf.map((c, i) => {
    const { menuTitle } = c
    return {
      label: menuTitle,
      onClick: () => {
        if (typeof onProgressBarClick === 'function') {
          if (onProgressBarClick(i)) {
            setPageIndex(i)
          }
        } else {
          setPageIndex(i)
        }
      }
    }
  })
  const progressBar = showTabsOrProgressBar && menuStyle === STYLE_PROGRESS_BAR ? (
    <ProgressBar
      currentStep={pageIndex + 1}
      stepLabels={stepLabels}
      total={tabsOptions.length}
      {...progressBarProps}
    />
  ) : undefined
  let backContinueBtns
  if (!hideButtonFooter && menuStyle === STYLE_PROGRESS_BAR) {
    const buttonGroupOptions = []
    if (pageIndex === 0) {
      buttonGroupOptions.push(returnBtnProps)
    }
    if (pageIndex > 0) {
      const { onClick: onBackClick } = backBtnProps
      buttonGroupOptions.push({
        ...backBtnProps,
        onClick: () => {
          const previousIndex = pageIndex - 1
          if (typeof onBackClick === 'function') {
            if (onBackClick(previousIndex)) {
              setPageIndex(previousIndex)
            }
          } else {
            setPageIndex(previousIndex)
          }
        }
      })
    }
    if (pageIndex < tabsOptions.length - 1) {
      const { onClick: onContinueClick } = continueBtnProps
      buttonGroupOptions.push({
        ...continueBtnProps,
        onClick: () => {
          const nextPageIndex = pageIndex + 1
          if (typeof onContinueClick === 'function') {
            if (onContinueClick(nextPageIndex)) {
              setPageIndex(nextPageIndex)
            }
          } else {
            setPageIndex(nextPageIndex)
          }
        }
      })
    }
    if (pageIndex === tabsOptions.length - 1) {
      buttonGroupOptions.push(completeBtnProps)
    }
    backContinueBtns = (
      <ButtonGroup
        className={buttonGroupClassName}
        options={buttonGroupOptions}
      />
    )
  }

  const groupTitle = pageTitle ? (<h3 className='group-title'>{pageTitle}</h3>) : undefined
  let editableElements
  if (editable) {
    editableElements = (
      <div>
        <br />
        <ButtonGroup
          className='alert alert-dark align-right'
          options={[
            {
              label: 'Edit pages',
              className: 'btn-sm',
              component: EditPopup,
              componentProps: {
                data: conf,
                onSave: setConf
              }
            }
          ]}
        />
      </div>
    )
  }

  const holderClassNameList = ['page-generator']
  if (holderClassName) {
    holderClassNameList.push(holderClassName)
  }

  const pageGenerator = (
    <div className={holderClassNameList.join(' ')}>
      <div className="page-generator-navigator">
        {tabs}
        {progressBar}
      </div>
      <div className='page-generator-page'>
        {groupTitle}
        <div className='page-generator-page-content'>{content}</div>
        {backContinueBtns}
        {editableElements}
      </div>
    </div>
  )

    if (title || subTitle) {
      return (
        <div>
          <h2 className='page-title'>
            {title}
            <small>{subTitle}</small>
          </h2>
          {pageGenerator}
        </div>
      )
    }

  return pageGenerator
}

PageGenerator.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  editable: PropTypes.bool,
  configuration: PropTypes.arrayOf(
    PropTypes.shape({
      pageTitle: PropTypes.string,
      menuTitle: PropTypes.string,
      content: PropTypes.node
    })
  ),
  menuStyle: PropTypes.oneOf([
    STYLE_TABS,
    STYLE_PROGRESS_BAR
  ]),
  pageIndex: PropTypes.number,
  holderClassName: PropTypes.string,
  /** See ProgressBar's "PROPS & METHODS" */
  progressBarProps: PropTypes.object,
  returnBtnProps: PropTypes.shape({
    className: PropTypes.string,
    label: PropTypes.node,
    onClick: PropTypes.func
  }),
  backBtnProps: PropTypes.shape({
    className: PropTypes.string,
    label: PropTypes.node,
    onClick: PropTypes.func
  }),
  continueBtnProps: PropTypes.shape({
    className: PropTypes.string,
    label: PropTypes.node,
    onClick: PropTypes.func
  }),
  completeBtnProps: PropTypes.shape({
    className: PropTypes.string,
    label: PropTypes.node,
    onClick: PropTypes.func
  }),
  onProgressBarClick: PropTypes.func,
  hideButtonFooter: PropTypes.bool,
  buttonGroupClassName: PropTypes.string
}

PageGenerator.defaultProps = {
  configuration: [],
  editable: false,
  menuStyle: STYLE_TABS,
  pageIndex: 0,
  returnBtnProps: {
    className: 'btn-outline-cancel',
    label: 'Return'
  },
  backBtnProps: {
    className: 'btn-outline-cancel',
    label: 'Back'
  },
  continueBtnProps: {
    className: 'btn-primary',
    label: 'Continue'
  },
  completeBtnProps: {
    className: 'btn-primary',
    label: 'Complete'
  }
}

export default PageGenerator
