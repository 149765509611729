export const STANDARD_CONFIGURATION = [
  {
    "title": "Address Line 1",
    "labelClassName": "question-label",
    "holderClassName": "horizontal"
  },
  {
    "title": "Address Line 2",
    "labelClassName": "question-label",
    "holderClassName": "horizontal"
  },
  {
    "title": "City",
    "labelClassName": "question-label",
    "holderClassName": "horizontal"
  },
  {
    "title": "State",
    "labelClassName": "question-label",
    "holderClassName": "horizontal"
  },
  {
    "title": "Postal Code",
    "labelClassName": "question-label",
    "holderClassName": "horizontal"
  }
]
