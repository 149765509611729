import React from 'react'
import PropTypes from 'prop-types'

function Link (props) {
  const { children, className, path, onClick } = props
  return (
    <a
      className={className}
      href={path}
      onClick={onClick}
    >
      {children}
    </a>
  )
}

Link.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  path: PropTypes.string
}

export default Link
