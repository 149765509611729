import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Popup from '../Popup'
import Card from '../../generic/Card'

import './style.css'

function mergeClassName(classNameList) {
  return classNameList.filter(c => c).join(' ')
}

function Lightbox (props) {
  const {
    children,
    defaultOpen,
    onClose,
    holderClassName,
    modalClassName,
    contentClassName
  } = props
  const [open, setOpen] = useState(defaultOpen)
  const close = () => {
    // force useEffect update in Popup by using other falsy value than false
    setOpen(open !== null ? null : undefined)
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  useEffect(() => {
    setOpen(defaultOpen)
  }, [defaultOpen])

  let leftHeader
  let rightHeader
  let body = children
  if (Array.isArray(children)) {
    if (children.length >= 2) {
      body = children[0]
      leftHeader = children[1]
    }
    if (children.length > 2) {
      rightHeader = children[2]
    }
  }

  const cardHeader = (leftHeader || rightHeader) ? (
    <Card holderClassName='card-panel'>
      {leftHeader}
      {rightHeader}
    </Card>
  ) : undefined
  const header = (
    <div key='lightbox-header'>
      {cardHeader}
      <i
        className='fa fa-times-circle lightbox-close'
        onClick={close}
      />
      <div className='lightbox-close-background' />
    </div>
  )

  return (
    <Popup
      {...props}
      defaultOpen={defaultOpen}
      holderClassName={mergeClassName([holderClassName, 'lightbox'])}
      modalClassName={mergeClassName([modalClassName, 'modal-fullscreen'])}
      contentClassName={mergeClassName([contentClassName, 'no-separator'])}
      noFooter
    >
      {[
        header,
        body,
        null
      ]}
    </Popup>
  )
}

Lightbox.propTypes = {
  /**
   * children = 2, body = children[0], leftHeader = children[1]
   * children > 2, body = children[0], leftHeader = children[1], rightHeader = children[2]
   * otherwise, body = children
   */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  onClose: PropTypes.func
}

export default Lightbox
