import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Popup from '../Popup'
import EditTable, {
  TYPE_LABEL,
  TYPE_TEXT,
  TYPE_TYPES_DROPDOWN,
  TYPE_OPTIONS_TABLE,
  TYPE_MOVE,
  TYPE_REMOVE
} from './EditTable'

export {
  TYPE_LABEL,
  TYPE_TEXT,
  TYPE_TYPES_DROPDOWN,
  TYPE_OPTIONS_TABLE,
  TYPE_MOVE,
  TYPE_REMOVE
}

function EditTablePopup (props) {
  const { addBtnLabel, columnMetadata, defaultNewRow, popupProps, tableBuilderProps } = props
  const { data } = tableBuilderProps
  const { onSave } = popupProps
  const [newData, setNewData] = useState(data)

  useEffect(() => {
    setNewData(data)
  }, [data])

  const save = () => {
    if (typeof onSave === 'function') {
      onSave(newData)
    }
  }

  return (
    <Popup
      {...popupProps}
      onSave={save}
    >
      <EditTable
        tableBuilderProps={tableBuilderProps}
        columnMetadata={columnMetadata}
        onDataChange={setNewData}
        addBtnLabel={addBtnLabel}
        defaultNewRow={defaultNewRow}
      />
    </Popup>
  )
}

EditTablePopup.propTypes = {
  /** See Popup's "PROPS & METHODS" */
  popupProps: PropTypes.object,
  /** See TableBuilder's "PROPS & METHODS" */
  tableBuilderProps: PropTypes.object,
  /** See EditTable's "PROPS & METHODS" */
  columnMetadata: PropTypes.array,
  addBtnLabel: PropTypes.string,
  defaultNewRow: PropTypes.object
}

export default EditTablePopup
