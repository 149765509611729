import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../../generic/Button'
import TextInput from '../../formElements/TextInput'
import ElementHolder from '../../formElements/ElementHolder'
import { get, set, isEmpty } from '../../../utils'

import './style.css'

/** Also all props of **ElementHolder** */
function List (props) {
  const {
    addLabel,
    className,
    removeBtnClassName,
    value,
    item: Item,
    sharedProps,
    name,
    hideRemoveBtn,
    defaultItem,
    onUpdate,
    onRemove,
    showRowNumber,
    tabIndex,
    itemNamePostfix,
    readOnly,
    visibility,
    willUpdateWholeList,
    useEmptyFieldToAddNewOption
  } = props
  const [newValue, setNewValue] = useState(value || defaultItem)

  useEffect(() => {
    setNewValue(value)
  }, [value])

  if (visibility === false) {
    return false
  }

  const onAdd = () => {
    let newItem
    if (itemNamePostfix) {
      newItem = {}
      set(newItem, itemNamePostfix, defaultItem)
    } else {
      newItem = defaultItem
    }

    const newV = [...newValue, newItem]
    setNewValue(newV)
  }

  const remove = index => {
    const valueFiltered = newValue.filter((d, i) => i !== index)
    setNewValue(valueFiltered)
    if (typeof onRemove === 'function') {
      onRemove(newValue[index], name, newValue)
    } else if (typeof onUpdate === 'function') {
      onUpdate(valueFiltered, name)
    }
  }

  const update = (val, itemName, index) => {
    if (val === '' || val === undefined) {
      remove(index)
      return
    }

    const newV = [...newValue]

    if (itemNamePostfix) {
      const newItem = newV[index] || {}
      if (willUpdateWholeList && Array.isArray(val)) {
        set(newItem, itemNamePostfix, val[index])
      } else {
        set(newItem, itemNamePostfix, val)
      }
      newV[index] = newItem
    } else {
      if (willUpdateWholeList && Array.isArray(val)) {
        newV[index] = val[index]
      } else {
        newV[index] = val
      }
    }

    setNewValue(newV)

    if (typeof onUpdate === 'function') {
      onUpdate(val, itemName)
    }
  }

  const removeBtnClassNameList = ['btn-link']
  if (removeBtnClassName) {
    removeBtnClassNameList.push(removeBtnClassName)
  }

  const options = [...newValue]
  if (!readOnly && useEmptyFieldToAddNewOption && defaultItem !== undefined) {
    options.push(defaultItem)
  }
  const items = options.map((d, i) => {
    let itemName
    if (name.includes('[*]')) {
      itemName = name.replace(/\[\*\]/, `[${i}]`)
    } else {
      itemName = `${name}[${i}]`
    }

    let itemValue = d
    if (itemNamePostfix) {
      itemName = `${itemName}.${itemNamePostfix}`
      itemValue = get(d, itemNamePostfix)
    }

    const key = `${itemName}-${itemValue}-${i}`
    const removeBtn = (isEmpty(d) || hideRemoveBtn || readOnly) ? undefined : (
      <div className='list-remove-btn-holder'>
        <Button
          className={removeBtnClassNameList.join(' ')}
          onClick={() => remove(i)}
        >
          <i className='fa fa-times' />
        </Button>
      </div>
    )
    const rowNumber = showRowNumber ? (
      <div className='list-item-row-number'>{i + 1}.</div>
    ) : undefined

    return (
      <li
        key={key}
        className='list-group-item'
      >
        {rowNumber}
        <div className='list-item-holder'>
          <Item
            tabIndex={tabIndex}
            name={itemName}
            onUpdate={(v, n) => update(v, n, i)}
            value={itemValue}
            readOnly={readOnly}
            {...sharedProps}
          />
        </div>
        {removeBtn}
      </li>
    )
  })

  let classNameList = ['list-group']
  if (className) {
    classNameList.push(className)
  }

  let addBtn
  if (!readOnly && !useEmptyFieldToAddNewOption) {
    addBtn = (
      <Button
        className='btn-link btn-list-add'
        onClick={onAdd}
      >
        {addLabel}
      </Button>
    )
  }

  const elementHolderProps = {...props}
  elementHolderProps.readOnly = false

  return (
    <ElementHolder
      {...elementHolderProps}
      childrenInReadOnlyHolder={readOnly}
    >
      <div className='form-element-list'>
        <ul className={classNameList.join(' ')}>
          {items}
        </ul>
        {addBtn}
      </div>
    </ElementHolder>
  )
}

List.propTypes = {
  addLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  item: PropTypes.func,
  sharedProps: PropTypes.object,
  defaultItem: PropTypes.any,
  /** .list-group-flush, .no-style, etc. */
  className: PropTypes.string,
  removeBtnClassName: PropTypes.string,
  hideRemoveBtn: PropTypes.bool,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  showRowNumber: PropTypes.bool,
  tabIndex: PropTypes.number,
  itemNamePostfix: PropTypes.string,
  readOnly: PropTypes.bool,
  visibility: PropTypes.bool,
  willUpdateWholeList: PropTypes.bool,
  useEmptyFieldToAddNewOption: PropTypes.bool
}

List.defaultProps = {
  addLabel: '+ Add',
  value: [],
  sharedProps: {},
  defaultItem: '',
  tabIndex: 1,
  item: TextInput,
  visibility: true
}

export default List
