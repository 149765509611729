import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../../generic/Button'

import './style.css'

function getDefaultHeader ({ title, onClose }) {
  return (
    <Fragment>
      <h5
        key='modal-header-title'
        className='modal-title'
      >
        {title}
      </h5>
      <button
        key='modal-header-x-icon'
        type='button'
        className='close'
        data-dismiss='modal'
        aria-label='Close'
        onClick={onClose}
      >
        <span aria-hidden='true'>&times;</span>
      </button>
    </Fragment>
  )
}

function getDefaultFooter ({ onClose, onSave }) {
  return (
    <Fragment>
      <Button
        key='modal-footer-close-button'
        className='btn-primary'
        onClick={onSave}
      >
        Save
      </Button>
      <Button
        key='modal-footer-save-button'
        className='btn-secondary'
        onClick={onClose}
      >
        Close
      </Button>
    </Fragment>
  )
}

function Popup (props) {
  const {
    children,
    title,
    modalClassName,
    contentClassName,
    modalBodyClassName,
    holderClassName,
    trigger,
    triggerClassName,
    onSave,
    onClose,
    defaultOpen,
    inlinePopup,
    returnChildrenDirectly,
    contentRef,
    noFooter
  } = props
  const [open, setOpen] = useState(defaultOpen)
  const popupContentDom = useRef()

  useEffect(() => {
    if (contentRef && popupContentDom.current) {
      contentRef.current = popupContentDom.current
    }
  }, [contentRef, popupContentDom])

  useEffect(() => {
    setOpen(defaultOpen)
  }, [defaultOpen])

  const close = () => {
    setOpen(false)
    if (typeof onClose === 'function') {
      onClose()
    }
  }
  const save = () => {
    setOpen(false)
    if (typeof onSave === 'function') {
      onSave()
    }
  }

  let popup
  if (returnChildrenDirectly) {
    popup = children
  } else {
    let header = getDefaultHeader({ title, onClose: close })
    let body = children
    let footer = getDefaultFooter({ onClose: close, onSave: save })
    if (Array.isArray(children)) {
      if (children.length === 2) {
        body = children[0]
        footer = children[1]
      } else if (children.length === 3) {
        header = children[0]
        body = children[1]
        footer = children[2]
      }
    }

    const contentClassNameList = ['modal-content']
    if (contentClassName) {
      contentClassNameList.push(contentClassName)
    }
    const modalBodyClassNameList = ['modal-body']
    if (modalBodyClassName) {
      modalBodyClassNameList.push(modalBodyClassName)
    }

    const footerHolder = noFooter ? null : (
      <div className='modal-footer'>
        {footer}
      </div>
    )
    popup = (
      <div
        ref={popupContentDom}
        className={contentClassNameList.join(' ')}
      >
        <div className='modal-header'>
          {header}
        </div>
        <div className={modalBodyClassNameList.join(' ')}>
          {body}
        </div>
        {footerHolder}
      </div>
    )
  }

  if (!inlinePopup) {
    const classNameList = ['modal']
    if (holderClassName) {
      classNameList.push(holderClassName)
    }
    if (open) {
      classNameList.push('fade', 'show')
    }
    const modalClassNameList = ['modal-dialog']
    if (modalClassName) {
      modalClassNameList.push(modalClassName)
    }
    if (!returnChildrenDirectly) {
      popup = (
        <div className={modalClassNameList.join(' ')} role='document'>
          {popup}
        </div>
      )
    }
    popup = (
      <div className={classNameList.join(' ')} tabIndex='-1' role='dialog'>
        {popup}
      </div>
    )
  }

  if (trigger) {
    return (
      <span className='btn-holder'>
        <Button
          className={triggerClassName}
          onClick={() => setOpen(true)}
        >
          {trigger}
        </Button>
        {popup}
      </span>
    )
  }

  return popup
}

Popup.propTypes = {
  holderClassName: PropTypes.string,
  /** .modal-lg, .modal-sm, .modal-fullscreen, .grass */
  modalClassName: PropTypes.string,
  modalBodyClassName: PropTypes.string,
  /** .no-separator */
  contentClassName: PropTypes.string,
  trigger: PropTypes.node,
  triggerClassName: PropTypes.string,
  title: PropTypes.string,
  /**
   * children = 2, body = children[0], footer = children[1]
   * children > 2, header = children[0], body = children[1], footer = children[2]
   * otherwise, body = children
   */
  children: PropTypes.node,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  defaultOpen: PropTypes.bool,
  noFooter: PropTypes.bool,
  /** inline-popup */
  inlinePopup: PropTypes.bool,
  returnChildrenDirectly: PropTypes.bool,
  contentRef: PropTypes.object
}

Popup.defaultProps = {
  defaultOpen: false
}

export default Popup
