import React from 'react'
import PropTypes from 'prop-types'
import { VALIDATION_MAP } from './config'

import './style.css'

export const VALIDATION_CONDITION_EQUAL = 'equal'
export const VALIDATION_CONDITION_NOT_EQUAL = 'notEqual'
export const VALIDATION_POSITION_BEFORE = 'before'
export const VALIDATION_POSITION_AFTER = 'after'
export const VALIDATION_DISPLAY_IN_HOLDER = 'holder'
export const VALIDATION_DISPLAY_IN_ELEMENT = 'element'
export const VALIDATION_DISPLAY_IN_BOTH = 'both'
export const VALIDATION_MESSAGE_TYPE_ALERT = 'alert'
export const VALIDATION_MESSAGE_TYPE_WARNING = 'warning'

export function showInsideHolder (props) {
  const { title, subTitle, description } = props

  return title || subTitle || description
}

function ElementHolder (props) {
  const {
    title,
    subTitle,
    description,
    holderClassName,
    labelClassName,
    children,
    readOnly,
    value,
    itemComponent,
    childrenInReadOnlyHolder,
    validation,
    alertMessage,
    noAlert,
    childrenOnly,
    visibility,
    warning
  } = props

  if (visibility === false) {
    return false
  }

  let holderClassNameList = ['form-group']
  if (holderClassName) {
    holderClassNameList.push(holderClassName)
  }

  let labelClassNameList = ['form-control-label']
  if (labelClassName) {
    labelClassNameList.push(labelClassName)
  }

  let titleLabel = (title || subTitle) ? (
    <div className='form-element-title'>
      {title}
      <span className='form-element-subtitle'>{subTitle}</span>
    </div>
  ) : undefined

  let descriptionLabel = description ? (
    <div className='form-element-description'>{description}</div>
  ) : undefined

  const displayedValue = typeof itemComponent === 'function' ? itemComponent({ data: value }) : value
  let content
  if (childrenInReadOnlyHolder) {
    content = (
      <div className='read-only-holder'>
        {children}
      </div>
    )
  } else if (readOnly) {
    content = (
      <div className='read-only-holder'>
        {displayedValue}
      </div>
    )
  } else {
    content = children
  }

  let validationMessage = alertMessage
  if (validation && value) {
    let validationData = validation
    if (validation === 'email' && VALIDATION_MAP['email']) {
      validationData = VALIDATION_MAP['email']
    }
    const { regex, message, condition, displayIn } = validationData
    if (displayIn !== VALIDATION_DISPLAY_IN_ELEMENT) {
      if (regex) {
        const reg = new RegExp(regex)
        if (reg.test(value)) {
          if (condition === VALIDATION_CONDITION_EQUAL) {
            validationMessage = message
          }
        } else if (condition === VALIDATION_CONDITION_NOT_EQUAL) {
          validationMessage = message
        }
      }
    }
  }

  const validationElement = !noAlert && validationMessage ? (
    <div className='validation-holder'>{validationMessage}</div>
  ) : undefined

  if (childrenOnly) {
    return content
  } else if (showInsideHolder(props)) {
    return (
      <div className={holderClassNameList.join(' ')}>
        <label className={labelClassNameList.join(' ')}>
          {titleLabel}
          {descriptionLabel}
        </label>
        {content}
        {validationElement}
        {warning}
      </div>
    )
  } else if (validationElement) {
    return (
      <div className={holderClassNameList.join(' ')}>
        {content}
        {validationElement}
        {warning}
      </div>
    )
  } else {
    return content
  }
}

ElementHolder.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  holderClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  children: PropTypes.node,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  itemComponent: PropTypes.func,
  /** display whole UI component in read-only, rather than string value */
  childrenInReadOnlyHolder: PropTypes.bool,
  validation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      regex: PropTypes.string,
      message: PropTypes.node,
      condition: PropTypes.oneOf([
        VALIDATION_CONDITION_EQUAL,
        VALIDATION_CONDITION_NOT_EQUAL
      ]),
      conditionFunc: PropTypes.func,
      mapFunc: PropTypes.func,
      messageType: PropTypes.oneOf([
        VALIDATION_MESSAGE_TYPE_ALERT,
        VALIDATION_MESSAGE_TYPE_WARNING
      ]),
      /** to be determinated */
      position: PropTypes.oneOf([
        VALIDATION_POSITION_AFTER,
        VALIDATION_POSITION_BEFORE
      ]),
      displayIn: PropTypes.oneOf([
        VALIDATION_DISPLAY_IN_HOLDER,
        VALIDATION_DISPLAY_IN_ELEMENT,
        VALIDATION_DISPLAY_IN_BOTH
      ])
    })
  ]),
  alertMessage: PropTypes.node,
  noAlert: PropTypes.bool,
  childrenOnly: PropTypes.bool,
  warning: PropTypes.node,
  visibility: PropTypes.bool
}

ElementHolder.defaultProps = {
  holderClassName: 'vertical'
}

export default ElementHolder
