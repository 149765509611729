/** Should move this class under ../../tableElements */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TableBuilder from '../../tableElements/TableBuilder'
import Dropdown from '../../formElements/Dropdown'
import TextInput from '../../formElements/TextInput'
import Button from '../../generic/Button'

export const TYPE_LABEL = 'label'
export const TYPE_TEXT = 'text'
export const TYPE_TYPES_DROPDOWN = 'types_dropdown'
export const TYPE_OPTIONS_TABLE = 'options_table'
export const TYPE_MOVE = 'move'
export const TYPE_REMOVE = 'remove'

const TYPE_DROPDOWN_OPTIONS = [
  { label: 'Textinput', value: 'text' },
  { label: 'Radio', value: 'radio' },
  { label: 'Buttons', value: 'buttons' },
  { label: 'Boolean Radio', value: 'boolean_radio' },
  { label: 'Boolean Buttons', value: 'boolean_buttons' },
  { label: 'Textarea', value: 'textarea' },
  { label: 'Password', value: 'password' }
]

const TYPES_REQUIRE_OPTIONS_FIELD = [
  'buttons',
  'radio'
]

function getFormElementFactory ({ field, setData, type }) {
  return (val, row, rowIndex, data) => {
    const onChange = ev => {
      const value = (typeof ev === 'object' && ev.target) ? ev.target.value : ev
      const newRow = {...row}
      const newData = [...data]
      newRow[field] = value
      newData[rowIndex] = newRow
      setData(newData)
    }

    if (type === TYPE_TYPES_DROPDOWN) {
      return (
        <Dropdown
          className='btn-secondary'
          name={`${field}-${rowIndex}`}
          options={TYPE_DROPDOWN_OPTIONS}
          selectedItem={TYPE_DROPDOWN_OPTIONS.find(d => d.value === val)}
          onChange={onChange}
        />
      )
    } else if (type === TYPE_OPTIONS_TABLE) {
      const { type: t } = row
      if (!TYPES_REQUIRE_OPTIONS_FIELD.includes(t)) {
        return 'N/A'
      }

      return (
        <EditTable
          tableBuilderProps={{
            keyField: 'label',
            data: val || []
          }}
          columnMetadata={[
            {
              dataField: 'label',
              text: 'Label',
              headerAlign: 'center'
            },
            {
              dataField: 'value',
              text: 'Value',
              headerAlign: 'center'
            },
            {
              dataField: 'move',
              text: 'move',
              isDummyField: true,
              type: TYPE_MOVE,
              align: 'center',
              headerAlign: 'center'
            },
            {
              dataField: 'remove',
              text: 'remove',
              isDummyField: true,
              type: TYPE_REMOVE,
              align: 'center',
              headerAlign: 'center'
            }
          ]}
          onDataChange={onChange}
          addBtnLabel='Add new options'
          defaultNewRow={{
            label: 'New option',
            value: 'new'
          }}
        />
      )
    } else if (type === TYPE_MOVE) {
      const moveUp = () => {
        const newData = [...data]
        const previousRow = newData[rowIndex - 1]
        newData[rowIndex - 1] = row
        newData[rowIndex] = previousRow
        setData(newData)
      }
      const moveDown = () => {
        const newData = [...data]
        const nextRow = newData[rowIndex + 1]
        newData[rowIndex + 1] = row
        newData[rowIndex] = nextRow
        setData(newData)
      }

      return (
        <div className='buttons-holder'>
          <Button
            className='btn-sm btn-light'
            disabled={rowIndex === 0}
            onClick={moveUp}
          >
            <i className='fa fa-chevron-up' />
          </Button>
          <Button
            className='btn-sm btn-light'
            disabled={rowIndex === data.length - 1}
            onClick={moveDown}
          >
            <i className='fa fa-chevron-down' />
          </Button>
        </div>
      )
    } else if (type === TYPE_REMOVE) {
      const onRemove = () => {
        const newData = data.filter((d , i) => i !== rowIndex)
        setData(newData)
      }

      return (
        <Button
          className='btn-sm btn-danger'
          onClick={onRemove}
          disabled={data.length <= 1}
        >
          &times;
        </Button>
      )
    }

    const key = `${field}-${rowIndex}`
    return (
      <TextInput
        key={key}
        name={key}
        value={val}
        onUpdate={onChange}
      />
    )
  }
}

function getColumns ({ columnMetadata, data, setData }) {
  return columnMetadata.map(meta => {
    const { dataField, type } = meta
    const metaWithoutType = {...meta}
    delete metaWithoutType.type

    return {
      ...metaWithoutType,
      formatter: getFormElementFactory({ field: dataField, setData, type }),
      formatExtraData: data
    }
  })
}

function EditTable (props) {
  const { addBtnLabel, columnMetadata, defaultNewRow, tableBuilderProps, onDataChange } = props
  const { data } = tableBuilderProps
  const [newData, setNewData] = useState(data)

  useEffect(() => {
    setNewData(data)
  }, [data])

  let columns = []
  if (Array.isArray(columnMetadata)) {
    columns = getColumns({
      columnMetadata,
      data: newData,
      setData: d => {
        setNewData(d)
        if (typeof onDataChange === 'function') {
          onDataChange(d)
        }
      }
    })
  } else if (tableBuilderProps.columns) {
    columns = tableBuilderProps.columns
  }

  const onAdd = () => {
    const value = [...newData, defaultNewRow]
    setNewData(value)
    if (typeof onDataChange === 'function') {
      onDataChange(value)
    }
  }

  return (
    <div>
      <TableBuilder
        {...tableBuilderProps}
        columns={columns}
        data={newData}
      />
      <Button
        className='btn-light'
        onClick={onAdd}
      >
        {addBtnLabel}
      </Button>
    </div>
  )
}

EditTable.propTypes = {
  /** See TableBuilder's "PROPS & METHODS" */
  tableBuilderProps: PropTypes.object,
  /** TableBuilder's `columns` will be set automatically if `columnMetadata` is set */
  columnMetadata: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string,
      text: PropTypes.string,
      /** label, text, dropdown */
      type: PropTypes.oneOf([
        TYPE_LABEL,
        TYPE_TEXT,
        TYPE_TYPES_DROPDOWN,
        TYPE_OPTIONS_TABLE,
        TYPE_MOVE,
        TYPE_REMOVE
      ])
    })
  ),
  addBtnLabel: PropTypes.string,
  defaultNewRow: PropTypes.object,
  onDataChange: PropTypes.func
}

EditTable.defaultProps = {
  addBtnLabel: '+ Add',
  defaultNewRow: {}
}

export default EditTable
