import React from 'react'
import PropTypes from 'prop-types'

function HeaderContainer (props) {
  return (
    <header className='header-navbar-container'>
      {props.children}
    </header>
  )
}

HeaderContainer.propTypes = {
  children: PropTypes.node.isRequired
}

export default HeaderContainer
