import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

function Button (props) {
  const { children, className, disabled, name, onClick } = props
  const classNameList = ['btn']
  if (className) {
    classNameList.push(className)
  }

  let clickEvent
  if (disabled) {
    classNameList.push('disabled')
  } else {
    clickEvent = onClick
  }

  return (
    <button
      name={name}
      className={classNameList.join(' ')}
      onClick={clickEvent}
      type='button'
      disabled={disabled}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func
}

export default Button
