import React from 'react'
import PropTypes from 'prop-types'

function Tabs (props) {
  const { options, pageIndex } = props
  const rows = options.map((opt, i) => {
    const { label, onClick } = opt || {}
    const classNameList = ['nav-link']
    if (pageIndex === i) {
      classNameList.push('active')
    }
    return (
      <li
        key={`${label}-tab-li`}
        className='nav-item'
        onClick={() => {
          if (typeof onClick === 'function') {
            onClick()
          }
        }}
      >
        <a className={classNameList.join(' ')}>
          {label}
        </a>
      </li>
    )
  })

  return (
    <ul className='nav nav-tabs'>
      {rows}
    </ul>
  )
}

Tabs.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func
    })
  ),
  pageIndex: PropTypes.number
}

export default Tabs
