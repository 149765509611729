import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ElementHolder from '../../formElements/ElementHolder'
import { get } from '../../../utils'

import './style.css'

export const RELATION_TYPE_VISIBILITY = 'visibility'
export const RELATION_TYPE_EDITABLITY = 'editability'
export const RELATION_TYPE_DISABILITY = 'disability'

const DEFAULT_CONDITION = ({ value, projection, projToReplace }) => {
  if (value) {
    return projection
  }

  return projToReplace || false
}

/** Also all props of **ElementHolder** */
function Relation (props) {
  const {
    data,
    trigger: Trigger,
    triggerProps,
    projection: Proj,
    projectionProps,
    relationType,
    children,
    className,
    condition,
    mapValue,
    visibility,
    onUpdate,
    holderClassName,
    disabled
  } = props
  const { name: tName, value: tValue } = triggerProps
  const { name: pName, value: pValue } = projectionProps
  let triggerValue = tValue
  let projValue = pValue
  if (data && mapValue) {
    if (!triggerValue) {
      triggerValue = mapValue({ data, name: tName })
    }
    if (!projValue) {
      projValue = mapValue({ data, name: pName })
    }
  }

  const [value, setValue] = useState(triggerValue)
  useEffect(() => {
    if (mapValue) {
      setValue(mapValue({ data, name: tName }))
    }
  }, [data])


  if (visibility === false) {
    return false
  }

  let proj
  let projToReplace
  if (children) {
    proj = children
  } else if (Proj) {
    proj = (
      <Proj
        {...projectionProps}
        value={projValue}
        onUpdate={onUpdate}
      />
    )

    if (relationType === RELATION_TYPE_EDITABLITY) {
      projToReplace = (
        <Proj
          {...projectionProps}
          value={projValue}
          readOnly
        />
      )
    } else if (relationType === RELATION_TYPE_DISABILITY) {
      projToReplace = (
        <Proj
          {...projectionProps}
          value={projValue}
          disabled
        />
      )
    }
  }
  const update = (val, name) => {
    if (typeof triggerProps.onUpdate === 'function') {
      triggerProps.onUpdate(val, name)
    }

    if (typeof onUpdate === 'function') {
      onUpdate(val, name)
    }

    setValue(val, name)
  }

  const classNameList = ['from-relation-holder']
  if (className) {
    classNameList.push(className)
  }

  let secondComponent
  if (condition === true) {
    secondComponent = proj
  } else {
    secondComponent = condition({ value, projection: proj, projToReplace })
  }

  const holderClassNameList = [holderClassName, 'no-bottom-margin']
  return (
    <ElementHolder
      {...props}
      holderClassName={holderClassNameList.join(' ')}
    >
      <div className={classNameList.join(' ')}>
        <Trigger
          {...triggerProps}
          onUpdate={update}
          value={triggerValue}
          disabled={disabled}
        />
        {secondComponent}
      </div>
    </ElementHolder>
  )
}

Relation.propTypes = {
  data: PropTypes.object,
  children: PropTypes.node,
  /** horizontal, etc. */
  className: PropTypes.string,
  trigger: PropTypes.func,
  triggerProps: PropTypes.object,
  projection: PropTypes.func,
  projectionProps: PropTypes.object,
  /** RELATION_TYPE_EDITABLITY works only passing projection (vs children) */
  relationType: PropTypes.oneOf([
    RELATION_TYPE_VISIBILITY,
    RELATION_TYPE_EDITABLITY,
    RELATION_TYPE_DISABILITY
  ]),
  onUpdate: PropTypes.func,
  condition: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func
  ]),
  value: PropTypes.any,
  mapValue: PropTypes.func,
  visibility: PropTypes.bool,
  disabled: PropTypes.bool
}

Relation.defaultProps = {
  condition: DEFAULT_CONDITION,
  projectionProps: {},
  relationType: RELATION_TYPE_VISIBILITY,
  holderClassName: 'vertical',
  visibility: true,
  mapValue: ({ data, name }) => get(data, name)
}

export default Relation
