import React from 'react'
import PropTypes from 'prop-types'

function Table (props) {
  const {
    keyField,
    columns,
    columnToggle,
    data,
    bordered,
    id,
    classes,
    headerWrapperClasses,
    bodyClasses,
    wrapperClasses,
    search,
    noDataLabel,
    withPagination,
    paginationOptions,
    selectRow
  } = props

  /**
   * TODO: Remove this component entirely.
   * This is a temporary step to make sure the main frontend app is working
   * with the dependency upgrades.
   */
  return null
}

Table.propTypes = {
  bordered: PropTypes.bool,
  keyField: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      hidden: PropTypes.bool,
      searchable: PropTypes.bool,
      sort: PropTypes.bool,
      /** (cell, row, rowIndex, formatExtraData) */
      formatter: PropTypes.func,
      formatExtraData: PropTypes.any,
      isDummyField: PropTypes.bool,
      align: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
      ]),
      headerAlign: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
      ])
    })
  ).isRequired,
  /** May be generated by ToolkitProvider in TableBuilder */
  columnToggle: PropTypes.object,
  data: PropTypes.array.isRequired,
  noDataLabel: PropTypes.string,
  id: PropTypes.string,
  classes: PropTypes.string,
  headerWrapperClasses: PropTypes.string,
  bodyClasses: PropTypes.string,
  wrapperClasses: PropTypes.string,
  /** May be generated by ToolkitProvider in TableBuilder */
  search: PropTypes.object,
  withPagination: PropTypes.bool,
  paginationOptions: PropTypes.shape({
    hideSizePerPage: PropTypes.bool,
    paginationSize: PropTypes.number,
    pageStartIndex: PropTypes.number,
    showTotal: PropTypes.bool,
    sizePerPage: PropTypes.number
  }),
  selectRow: PropTypes.shape({
    mode: PropTypes.oneOf(['checkbox', 'radio']),
    clickToSelect: PropTypes.bool,
    selected: PropTypes.arrayOf(PropTypes.number),
    onSelect: PropTypes.func,
    onSelectAll: PropTypes.func,
    hideSelectAll: PropTypes.bool,
    selectionHeaderRenderer: PropTypes.func,
    selectionRenderer: PropTypes.func
  })
}

Table.defaultProps = {
  bordered: true,
  noDataLabel: 'Record not found',
  paginationOptions: undefined
}

export default Table
