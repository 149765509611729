import React from 'react'
import PropTypes from 'prop-types'
import Dropdown from '../../../formElements/Dropdown'

import './style.css'

function Label (props) {
  const { accountImgSrc, onClick } = props

  return (
    <div
      className='header-account'
      onClick={onClick}
    >
      <img
        className='rounded-circle'
        src={accountImgSrc}
        alt='Profile'
      />
    </div>
  )
}

function AccountDropdown (props) {
  const { onLogout, accountImgSrc } = props
  const options = [
    {
      accountImgSrc,
      label: 'Logout',
      value: 'logout',
      onClick: onLogout
    }
  ]

  return (
    <Dropdown
      name='accountDropdown'
      options={options}
      placeholder={false}
    >
      {Label}
    </Dropdown>
  )
}

AccountDropdown.propTypes = {
  onLogout: PropTypes.func,
  accountImgSrc: PropTypes.string
}

AccountDropdown.defaultProps = {
  accountImgSrc: 'https://via.placeholder.com/42'
}

export default AccountDropdown
