import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

function Content (props) {
  const { className, children, title } = props
  const classNameList = ['content-holder']
  if (className) {
    classNameList.push(className)
  }

  const pageTitle = title ? (
    <h2 className='page-title'>
      {title}
    </h2>
  ) : undefined

  return (
    <section className={classNameList.join(' ')}>
      {pageTitle}
      {children}
    </section>
  )
}

Content.propTypes = {
  children: PropTypes.node,
  /** for example: `with-fixed-header` */
  className: PropTypes.string,
  title: PropTypes.string
}

export default Content
