// Warning: A component is changing an uncontrolled input of type checkbox to be controlled. Input elements should not switch from uncontrolled to controlled (or vice versa). Decide between using a controlled or uncontrolled input element for the lifetime of the component. More info: https://fb.me/react-controlled-components
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BOOLEAN_OPTIONS, SEVERITY_OPTIONS } from './config'
import ElementHolder from '../ElementHolder'
import Button from '../../generic/Button'

import './style.css'

export const RADIO_TYPE_BOOLEAN_RADIO = 'boolean_radio'
export const RADIO_TYPE_BOOLEAN_BUTTONS = 'boolean_buttons'
export const RADIO_TYPE_RADIO = 'radio'
export const RADIO_TYPE_BUTTONS = 'buttons'
export const RADIO_TYPE_CHECKBOX = 'checkbox'

export const READ_ONLY_STYLE_DEFAULT = 'default'
export const READ_ONLY_STYLE_LABEL = 'label'

function getDataLabel ({ options, value }) {
  if (Array.isArray(options)) {
    const opt = options.find(opt => (opt && opt.value) === value)
    if (opt && opt.label) {
      return opt.label
    }
  }

  return value ? value.toString() : ''
}

/** Also all props of **ElementHolder** */
function RadioGroup (props) {
  const {
    name,
    options,
    className: defaultClassName,
    type,
    value,
    readOnly: defaultReadOnly,
    readOnlyStyle,
    onUpdate,
    itemComponent: Item,
    activeBtnClassName,
    inactiveBtnClassName,
    btnClassName,
    visibility,
    includeSelectAll,
    isBooleanCheckbox,
    disabled: defaultDisabled
  } = props
  const [data, setData] = useState(value)
  const isCheckbox = type === RADIO_TYPE_CHECKBOX
  const isBoolCheckbox = isCheckbox && isBooleanCheckbox && options.length === 1
  const readOnly = defaultReadOnly && readOnlyStyle === READ_ONLY_STYLE_LABEL
  const disabledButton = defaultDisabled || (defaultReadOnly && readOnlyStyle === READ_ONLY_STYLE_DEFAULT)
  const disabledLabel = defaultDisabled

  useEffect(() => {
    setData(value)
  }, [value])

  if (visibility === false) {
    return false
  }

  function update (val) {
    let newVal
    if (isBoolCheckbox) {
      if (val !== data) {
        newVal = val
      }
    } else if (isCheckbox) {
      if (Array.isArray(data)) {
        if (data.includes(val)) {
          newVal = data.filter(d => d !== val)
        } else {
          newVal = [...data, val]
        }
      } else {
        newVal = [val]
      }
    } else {
      newVal = val
    }

    setData(newVal)
    onUpdate(newVal, name)
  }

  let radioGroup
  let opts
  if (type === RADIO_TYPE_BOOLEAN_RADIO || type === RADIO_TYPE_BOOLEAN_BUTTONS) {
    opts = BOOLEAN_OPTIONS
  } else if (options === 'severity') {
    opts = SEVERITY_OPTIONS
  } else {
    opts = options
  }

  if (readOnly) {
    if (Array.isArray(data)) {
      radioGroup = data.map(d => getDataLabel({ options: opts, value: d })).join(', ')
    } else {
      radioGroup = getDataLabel({ options: opts, value: data })
    }
  } else {
    radioGroup = opts.map(opt => {
      const {
        abbr,
        disabled = disabledButton,
        label,
        value: val,
        labelClassName,
        checked: defaultChecked
      } = opt
      const key = `${val}-radio`

      let checked
      if (isCheckbox && !isBoolCheckbox && Array.isArray(data)) {
        checked = data.includes(val) || defaultChecked
      } else {
        checked = val === data || defaultChecked
      }

      let bClassNameList = []
      if (checked) {
        bClassNameList.push(activeBtnClassName)
      } else {
        bClassNameList.push(inactiveBtnClassName)
      }
      if (btnClassName) {
        bClassNameList.push(btnClassName)
      }
      const bClassName = bClassNameList.join(' ')

      if (Item) {
        return (
          <Item
            key={key}
            className={bClassName}
            onClick={() => update(val)}
            {...opt}
          />
        )
      } else if (type === RADIO_TYPE_BUTTONS || type === RADIO_TYPE_BOOLEAN_BUTTONS) {
        return (
          <Button
            key={key}
            name={name}
            className={bClassName}
            onClick={() => update(val)}
            disabled={disabled}
          >
            {label || val}
          </Button>
        )
      }

      let inputName = name
      let inputType = 'radio'
      if (type === RADIO_TYPE_CHECKBOX) {
        inputName = `${inputName}-${val}`
        inputType = RADIO_TYPE_CHECKBOX
      }
      let abbrHolder
      if (abbr) {
        abbrHolder = (
          <small className='abbr'>{abbr}</small>
        )
      }

      const labelClassNameList = ['rdiobox']
      if (labelClassName) {
        labelClassNameList.push(labelClassName)
      }
      if (disabledLabel) {
        labelClassNameList.push('disabled')
      } else if (checked && disabled) {
        labelClassNameList.push('selected-read-only')
      }

      return (
        <label
          key={key}
          className={labelClassNameList.join(' ')}
        >
          <input
            type={inputType}
            name={inputName}
            value={val}
            checked={checked}
            onChange={() => update(val)}
            disabled={disabled}
          />
          <span>{label || val}</span>
          {abbrHolder}
        </label>
      )
    })
  }

  let className
  if (defaultClassName) {
    className = defaultClassName
  } else if (type === RADIO_TYPE_BOOLEAN_BUTTONS || type === RADIO_TYPE_BUTTONS) {
    className = 'buttons-holder'
  } else {
    className = 'radio-holder'
  }

  let selectAll
  if (type === RADIO_TYPE_CHECKBOX && includeSelectAll) {
    const onSelectAllClick = ev => {
      ev.preventDefault()
      const selectAllValues = opts.reduce((list, o) => {
        const { disabled, value } = o
        if (!disabled) {
          list.push(value)
        }
        return list
      }, [])

      setData(selectAllValues)
      onUpdate(selectAllValues, name)
    }
    selectAll = (
      <a
        className='select-all-link'
        href='#selectAll'
        onClick={ev => onSelectAllClick(ev)}
      >
        SELECT ALL
      </a>
    )
  }

  const component = (
    <div className={className}>
      {radioGroup}
      {selectAll}
    </div>
  )

  return (
    <ElementHolder
      {...props}
      childrenInReadOnlyHolder={readOnly}
      readOnly={readOnly}
    >
      {component}
    </ElementHolder>
  )
}

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool
        ])
      })
    ),
    PropTypes.string
  ]),
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array // for checkbox
  ]),
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  /** 'default', 'label' */
  readOnlyStyle: PropTypes.oneOf([
    READ_ONLY_STYLE_DEFAULT,
    READ_ONLY_STYLE_LABEL
  ]),
  onUpdate: PropTypes.func,
  itemComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ]),
  /** for buttons type only */
  activeBtnClassName: PropTypes.string,
  /** for buttons type only */
  inactiveBtnClassName: PropTypes.string,
  btnClassName: PropTypes.string,
  visibility: PropTypes.bool,
  /** For checkbox only */
  includeSelectAll: PropTypes.bool,
  isBooleanCheckbox: PropTypes.bool
}

RadioGroup.defaultProps = {
  readOnly: false,
  readOnlyStyle: READ_ONLY_STYLE_DEFAULT,
  style: RADIO_TYPE_RADIO,
  activeBtnClassName: 'btn-fill-secondary btn-in-form',
  inactiveBtnClassName: 'btn-stroke btn-in-form',
  visibility: true,
  onUpdate: (value, name) => {
    console.log('RadioGroup', 'onUpdate', 'name', name, 'value', value)
  }
}

export default RadioGroup
