// https://www.npmjs.com/package/react-datepicker
// https://reactdatepicker.com/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ReactDatePicker from 'react-datepicker'
import ElementHolder from '../ElementHolder'

import "react-datepicker/dist/react-datepicker.css"

/** Also all props of **ElementHolder** */
function DatePicker (props) {
  const {
    className,
    date,
    day,
    month,
    name,
    onUpdate,
    readOnly,
    year,
    value,
    visibility,
    disabled,
    placeholder
  } = props

  const [curDate, setCurDate] = useState(null)

  useEffect(() => {
    if (date instanceof Date) {
      setCurDate(date)
    } else if (year && month && day) {
      setCurDate(new Date(year, month - 1, day))
    } else if (value) {
      setCurDate(new Date(value.year, value.month - 1, value.day))
    }
  }, [date, year, month, day, value])

  if (visibility === false) {
    return false
  }

  const onDateChange = d => {
    if (!d) {
      setCurDate(undefined)
      onUpdate(undefined, name)
    } else if (d instanceof Date) {
      setCurDate(d)
      if (typeof onUpdate === 'function') {
        onUpdate({
          year: d.getFullYear(),
          month: d.getMonth() + 1,
          day: d.getDate()
        }, name)
      }
    }
  }

  return (
    <ElementHolder
      {...props}
      childrenInReadOnlyHolder={readOnly}
    >
      <div>
        <ReactDatePicker
          className={className}
          name={name}
          selected={curDate}
          onChange={onDateChange}
          disabled={readOnly || disabled}
          placeholderText={placeholder}
        />
      </div>
    </ElementHolder>
  )
}

DatePicker.propTypes = {
  className: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  day: PropTypes.number,
  month: PropTypes.number,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  year: PropTypes.number,
  value: PropTypes.shape({
    year: PropTypes.number,
    month: PropTypes.number,
    day: PropTypes.number
  }),
  visibility: PropTypes.bool,
  disabled: PropTypes.bool,
  onUpdate: PropTypes.func
}

DatePicker.defaultProps = {
  className: 'form-control',
  placeholder: 'MM/DD/YYYY'
}

export default DatePicker
