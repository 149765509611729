import React from 'react'
import PropTypes from 'prop-types'
import EditTablePopup, {
  TYPE_TYPES_DROPDOWN,
  TYPE_OPTIONS_TABLE,
  TYPE_MOVE,
  TYPE_REMOVE
} from '../../popups/EditTablePopup'

function EditPopup (props) {
  const { data, onSave } = props
  const popupProps = {
    title: 'Edit existing form elements',
    trigger: 'Edit existing form elements',
    triggerClassName: 'btn-sm',
    modalClassName: 'modal-fullscreen',
    onSave: d => onSave(d)
  }
  const tableBuilderProps = {
    keyField: 'name',
    data
  }
  const columnMetadata = [
    {
      dataField: 'title',
      text: 'Title',
      headerAlign: 'center'
    },
    {
      dataField: 'name',
      text: 'Name',
      headerAlign: 'center'
    },
    {
      dataField: 'type',
      text: 'Type',
      type: TYPE_TYPES_DROPDOWN,
      align: 'left',
      headerAlign: 'center'
    },
    {
      dataField: 'value',
      text: 'Value',
      headerAlign: 'center'
    },
    {
      dataField: 'options',
      text: 'Options',
      align: 'left',
      headerAlign: 'center',
      type: TYPE_OPTIONS_TABLE
    },
    {
      dataField: 'move',
      text: 'move',
      isDummyField: true,
      type: TYPE_MOVE,
      align: 'center',
      headerAlign: 'center'
    },
    {
      dataField: 'remove',
      text: 'remove',
      isDummyField: true,
      type: TYPE_REMOVE,
      align: 'center',
      headerAlign: 'center'
    }
  ]

  return (
    <EditTablePopup
      columnMetadata={columnMetadata}
      popupProps={popupProps}
      tableBuilderProps={tableBuilderProps}
      addBtnLabel='Add new form element'
      defaultNewRow={{
        title: 'New Input',
        name: 'newInput',
        type: 'text'
      }}
    />
  )
}

EditPopup.propTypes = {
  data: PropTypes.array,
  onSave: PropTypes.func.isRequired
}

export default EditPopup
