import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { get, set } from '../../../utils'
import ButtonGroup from '../../generic/ButtonGroup'
import ElementHolder, { showInsideHolder } from '../../formElements/ElementHolder'
import EditPopup from './EditPopup'
import getInvalidFields from './getInvalidFields'
import {
  DEFAULT_UI_COMPONENTS_MAP,
  DEFAULT_MAP_COMPONENT_NAME
} from './config'

import './style.css'

const FORM_GENERATOR_CONDITION_EXISTS = 'exists'
const FORM_GENERATOR_CONDITION_NOT_EXISTS = 'notExists'
const FORM_GENERATOR_CONDITION_EQUAL = 'equal'
const FORM_GENERATOR_CONDITION_NOT_EQUAL = 'notEqual'

function FormGenerator (props) {
  const {
    configuration,
    data: defaultData,
    editable,
    mapComponentName,
    mapComponentProps,
    uiComponentsMap,
    mapExtraCompName,
    onUpdate,
    extraFunctionMap,
    readOnly,
    requiredFields,
    requiredMessageMap,
    defaultRequiredMessage
  } = props
  const [conf, setConf] = useState(configuration)
  const [data, setData] = useState(defaultData)
  const update = (value, name, option = {}) => {
    const { silence } = option
    const newData = {...data}
    set(newData, name, value)
    setData(newData)

    if (!silence && typeof onUpdate === 'function') {
      onUpdate(value, name, newData)
    }
  }
  const mapValue = ({ data, name }) => get(data, name)

  useEffect(() => {
    setConf(configuration)
  }, [configuration])

  useEffect(() => {
    setData(defaultData)
  }, [defaultData])

  const invalidFields = getInvalidFields({ data, requiredFields, defaultRequiredMessage, requiredMessageMap })
  const uiComps = uiComponentsMap
    ? { ...DEFAULT_UI_COMPONENTS_MAP, ...uiComponentsMap }
    : DEFAULT_UI_COMPONENTS_MAP
  const components = conf.map((c, i) => {
    const { name, relation } = c
    const compName = mapComponentName(c, mapExtraCompName)
    let visibility = true
    let disabled = false
    let value = mapValue({ data, name })
    if (relation) {
      const {
        trigger,
        condition: rCond,
        relationType: rType,
        mapValueFunctionName: relationFunctionName,
        value: rValue
      } = relation
      let triggerValue

      if (typeof extraFunctionMap[relationFunctionName] === 'function') {
        triggerValue = extraFunctionMap[relationFunctionName]({ data, name: trigger, configuration: conf })
      } else if (typeof trigger === 'string') {
        triggerValue = mapValue({ data, name: trigger })
      } else if (Array.isArray(trigger)) {
        triggerValue = trigger.map(t => mapValue({ data, name: t }))
      }

      if (rCond === FORM_GENERATOR_CONDITION_EXISTS) {
        if (triggerValue === undefined && rType === 'visibility') {
          visibility = false
        } else if (triggerValue !== undefined && rType === 'disability') {
          disabled = true
        }
      } else if (rCond === FORM_GENERATOR_CONDITION_NOT_EXISTS) {
        if (triggerValue !== undefined && rType === 'visibility') {
          visibility = false
        } else if (triggerValue === undefined && rType === 'disability') {
          disabled = true
        }
      } else if (rType === 'sum') {
        if (Array.isArray(triggerValue)) {
          value = triggerValue.reduce((sum, v) => (sum + Number(v)), 0)
        } else {
          value = triggerValue
        }
        if (isNaN(value)) {
          value = undefined
        }
      } else if (rCond === FORM_GENERATOR_CONDITION_EQUAL && Array.isArray(rValue)) {
        if (
          (Array.isArray(triggerValue) && triggerValue.some(v => rValue.includes(v))) ||
          rValue.includes(triggerValue)
        ) {
          if (rType === 'disability') {
            disabled = true
          }
        } else {
          if (rType === 'visibility') {
            visibility = false
          }
        }
      } else if (rCond === FORM_GENERATOR_CONDITION_NOT_EQUAL && Array.isArray(rValue)) {
        if (
          (Array.isArray(triggerValue) && triggerValue.some(v => rValue.includes(v))) ||
          rValue.includes(triggerValue)
        ) {
          if (rType === 'visibility') {
            visibility = false
          }
        } else {
          if (rType === 'disability') {
            disabled = true
          }
        }
      }
    }

    const UI = uiComps[compName]
    return (
      <UI
        data={data}
        key={`ui-key-${name}-${i}`}
        onUpdate={update}
        value={value}
        mapValue={mapValue}
        visibility={visibility}
        disabled={disabled}
        readOnly={readOnly}
        {...mapComponentProps(c)}
        mapComponentName={mapComponentName}
        mapComponentProps={mapComponentProps}
        uiComponentsMap={uiComponentsMap}
        mapExtraCompName={mapExtraCompName}
        alertMessage={invalidFields[name]}
      />
    )
  });

  let editableElements
  if (editable) {
    editableElements = (
      <div>
        <br />
        <ButtonGroup
          className='alert alert-dark align-right'
          options={[
            {
              label: 'Edit pages',
              className: 'btn-sm',
              component: EditPopup,
              componentProps: {
                data: conf,
                onSave: setConf
              }
            }
          ]}
        />
      </div>
    )
  }

  const elementHolderProps = {...props}
  elementHolderProps.readOnly = false

  if (showInsideHolder(props)) {
    return (
      <div className='form-generator'>
        <ElementHolder
          {...elementHolderProps}
          holderClassName='vertical no-bottom-margin'
        >
          {components}
          {editableElements}
        </ElementHolder>
      </div>
    )
  }

  return (
    <div className='form-generator'>
      {components}
      {editableElements}
    </div>
  )
}

FormGenerator.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  configuration: PropTypes.array.isRequired,
  /** Allow to add, remove, and update Form Element (experimental) */
  editable: PropTypes.bool,
  readOnly: PropTypes.bool,
  mapComponentName: PropTypes.func,
  mapExtraCompName: PropTypes.func,
  mapComponentProps: PropTypes.func,
  uiComponentsMap: PropTypes.object,
  onUpdate: PropTypes.func,
  relation: PropTypes.shape({
    trigger: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    condition: PropTypes.oneOf([
      FORM_GENERATOR_CONDITION_EXISTS,
      FORM_GENERATOR_CONDITION_NOT_EXISTS,
      FORM_GENERATOR_CONDITION_EQUAL,
      FORM_GENERATOR_CONDITION_NOT_EQUAL
    ]),
    relationType: PropTypes.oneOf([
      'visibility',
      'disability',
      'sum'
    ]),
    mapValueFunctionName: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.bool)
    ]),
  }),
  extraFunctionMap: PropTypes.object,
  /** See ElementHolder's validation property */
  requiredFields: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.shape({
        path: PropTypes.string,
        isInvalidFunc: PropTypes.func
      })
    ])
  ),
  requiredMessageMap: PropTypes.object,
  defaultRequiredMessage: PropTypes.string
}

FormGenerator.defaultProps = {
  configuration: [],
  data: {},
  // Map display value; for example, joining an array of a string to an TextInput
  extraFunctionMap: {},
  mapComponentName: DEFAULT_MAP_COMPONENT_NAME,
  mapComponentProps: p => p,
  onUpdate: (value, name) => console.log('FormGenerator onUpdate', name, value),
  requiredFields: [],
  requiredMessageMap: {},
  defaultRequiredMessage: '* Required'
}

export default FormGenerator
