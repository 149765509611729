import React from 'react'
import PropTypes from 'prop-types'
import Link from '../Link'
import AccountDropdown from '../AccountDropdown'
import HeaderContainer from '../HeaderContainer'

import '../style.css'

function BackHeader (props) {
  const {
    backUrl,
    onBackClick,
    className,
    fullName,
    imgSource,
    children,
    onLogout,
    accountImgSrc
  } = props

  const classNameList = ['header-navbar', 'back-header-navbar']
  if (className) {
    classNameList.push(className)
  }

  return (
    <HeaderContainer>
      <div className={classNameList.join(' ')}>
        <div className='navbar-left'>
          <Link
            className='header-navbar-link'
            path={backUrl}
            onClick={onBackClick}
          >
            &laquo; BACK
          </Link>
          <div className='navbar-group'>
            <img
              className='rounded-circle'
              src={imgSource}
              alt='Profile'
            />
            <span className='header-navbar-title'>{fullName}</span>
          </div>
          {children}
        </div>
        <div className='navbar-right'>
          <AccountDropdown
            accountImgSrc={accountImgSrc}
            onLogout={onLogout}
          />
        </div>
      </div>
    </HeaderContainer>
  )
}

BackHeader.propTypes = {
  /** for example: `header-navbar-fixed` */
  className: PropTypes.string,
  backUrl: PropTypes.string,
  onBackClick: PropTypes.func,
  person: PropTypes.object,
  fullName: PropTypes.string,
  children: PropTypes.node,
  imgSource: PropTypes.string,
  onLogout: PropTypes.func,
  accountImgSrc: PropTypes.string
}

BackHeader.defaultProps = {
  backUrl: '/',
  fullName: 'Selected Person',
  imgSource: 'https://via.placeholder.com/42'
}

export default BackHeader
