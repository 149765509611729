import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import FormGenerator from '../FormGenerator'
import ButtonGroup from '../../generic/ButtonGroup'
import getInvalidFields from '../FormGenerator/getInvalidFields'
import { isEmpty } from '../../../utils'

function AdvancedFormGenerator (props) {
  const {
    data,
    buttonGroupClassName,
    footerButtonGroupOptions,
    requiredFields,
    cancelButtonClassName,
    cancelButtonLabel,
    cancelButtonClick,
    saveButtonClassName,
    saveButtonLabel,
    saveButtonClick,
    defaultShowRequiredFieldAlert
  } = props
  const [showRequiredFieldAlert, setShowRequiredFieldAlert] = useState(defaultShowRequiredFieldAlert)
  useEffect(() => {
    setShowRequiredFieldAlert(defaultShowRequiredFieldAlert)
  }, [defaultShowRequiredFieldAlert])

  const onSave = () => {
    const invalidFields = getInvalidFields({ data, requiredFields })
    if (!isEmpty(invalidFields)) {
      setShowRequiredFieldAlert(true)
      const elementNames = Object.keys(invalidFields)
      const elName = elementNames[0]
      const fieldList = document.getElementsByName(elName)
      if (fieldList.length) {
        const rf = fieldList[0]
        if (typeof rf.focus === 'function') {
          rf.focus()
        }
        if (typeof rf.select === 'function') {
          rf.select()
        }
      } else {
        console.warn(`Required field ${elName} cannot be found`)
      }
    } else if (typeof saveButtonClick === 'function') {
      return saveButtonClick(data)
    }
  }
  const formGenerator = (
    <FormGenerator
      {...props}
      requiredFields={showRequiredFieldAlert ? requiredFields : []}
    />
  )

  const buttonGroupOptions = []
  if (Array.isArray(footerButtonGroupOptions) && footerButtonGroupOptions.length) {
    buttonGroupOptions.push(...footerButtonGroupOptions)
  }
  if (cancelButtonLabel) {
    buttonGroupOptions.push({
      label: cancelButtonLabel,
      className: cancelButtonClassName,
      onClick: cancelButtonClick
    })
  }
  if (saveButtonLabel) {
    buttonGroupOptions.push({
      label: saveButtonLabel,
      className: saveButtonClassName,
      onClick: onSave
    })
  }
  if (buttonGroupOptions.length) {
    return (
      <Fragment>
        {formGenerator}
        <ButtonGroup
          className={buttonGroupClassName}
          options={buttonGroupOptions}
        />
      </Fragment>
    )
  }

  return formGenerator
}

AdvancedFormGenerator.propTypes = {
  data: PropTypes.object,
  buttonGroupClassName: PropTypes.string,
  footerButtonGroupOptions: PropTypes.array,
  onSave: PropTypes.func,
  cancelButtonClassName: PropTypes.string,
  cancelButtonLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  cancelButtonClick: PropTypes.func,
  saveButtonClassName: PropTypes.string,
  saveButtonLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  saveButtonClick: PropTypes.func,
  defaultShowRequiredFieldAlert: PropTypes.bool
}

/* AdvancedFormGenerator.defaultProps = {
} */

export default AdvancedFormGenerator
