import React from 'react'
import PropTypes from 'prop-types'
import Bar from './Bar'

import './style.css'

const PROGRESS_BAR_TYPE_DEFAULT = 'default'
const PROGRESS_BAR_TYPE_MULTI = 'multi'
const PROGRESS_BAR_MULTI_HIGHLIGHT_DEFAULT = 'default'
const PROGRESS_BAR_MULTI_HIGHLIGHT_TABS = 'tabs'
const PROGRESS_BAR_LABEL_POSITION_TOP = 'top'
const PROGRESS_BAR_LABEL_POSITION_BOTTOM = 'bottom'

function ProgressBar (props) {
  const {
    currentStep,
    multiHighlightType,
    showStepLabel,
    stepLabels,
    total,
    type,
    className: holderClassName,
    labelPosition
  } = props

  if (type === PROGRESS_BAR_TYPE_MULTI) {
    const bars = stepLabels.map((step, i) => {
      const isCurrentStep = currentStep - 1 === i
      let label
      let onClick
      if (step && typeof step === 'object') {
        label = step.label
        onClick = step.onClick
      } else {
        label = step
      }

      let w
      if (multiHighlightType === PROGRESS_BAR_MULTI_HIGHLIGHT_TABS) {
        w = isCurrentStep ? '100%' : '0%'
      } else {
        w = i < currentStep ? '100%' : '0%'
      }
      const classNameList = []
      if (typeof onClick === 'function') {
        classNameList.push('clickable')
      }
      if (isCurrentStep) {
        classNameList.push('active')
      }
      const pBar = (<Bar width={w} />)

      return (
        <div
          key={`${label}-${i}`}
          className={classNameList.join(' ')}
          onClick={() => {
            if (typeof onClick === 'function') {
              onClick()
            }
          }}
        >
          {labelPosition === PROGRESS_BAR_LABEL_POSITION_BOTTOM && pBar}
          <div className='progress-bar-label'>
            {label}
          </div>
          {labelPosition === PROGRESS_BAR_LABEL_POSITION_TOP && pBar}
        </div>
      )
    })

    const holderClassNameList = ['progress-bar-holder']
    if (holderClassName) {
      holderClassNameList.push(holderClassName)
    }

    return (
      <div className={holderClassNameList.join(' ')}>
        {bars}
      </div>
    )
  }

  const width = `${(currentStep / total) * 100}%`
  const progressBar = (<Bar width={width} />)

  if (showStepLabel) {
    return (
      <div>
        {labelPosition === PROGRESS_BAR_LABEL_POSITION_BOTTOM && progressBar}
        <div>Step {currentStep} / {total}</div>
        {labelPosition === PROGRESS_BAR_LABEL_POSITION_TOP && progressBar}
      </div>
    )
  }

  return progressBar
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  currentStep: PropTypes.number,
  showStepLabel: PropTypes.bool,
  stepLabels: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.onClick
      })
    ])
  ),
  total: PropTypes.number,
  type: PropTypes.oneOf([
    PROGRESS_BAR_TYPE_DEFAULT,
    PROGRESS_BAR_TYPE_MULTI
  ]),
  multiHighlightType: PropTypes.oneOf([
    PROGRESS_BAR_MULTI_HIGHLIGHT_DEFAULT,
    PROGRESS_BAR_MULTI_HIGHLIGHT_TABS
  ]),
  labelPosition: PropTypes.oneOf([
    PROGRESS_BAR_LABEL_POSITION_TOP,
    PROGRESS_BAR_LABEL_POSITION_BOTTOM
  ])
}

ProgressBar.defaultProps = {
  currentStep: 0,
  stepLabels: [],
  total: 10,
  type: PROGRESS_BAR_TYPE_DEFAULT,
  multiHighlightType: PROGRESS_BAR_MULTI_HIGHLIGHT_DEFAULT,
  labelPosition: PROGRESS_BAR_LABEL_POSITION_BOTTOM
}

export default ProgressBar
