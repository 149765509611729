import React from 'react'
import PropTypes from 'prop-types'
import Logo from '../Logo'
import Link from '../Link'
import AccountDropdown from '../AccountDropdown'
import HeaderContainer from '../HeaderContainer'

import '../style.css'

function PersonHeader (props) {
  const {
    className,
    fullName,
    linkUrl,
    linkLabel,
    onLinkClick,
    onLogout,
    accountImgSrc,
    logoSrc
  } = props

  const classNameList = ['header-navbar', 'person-header-navbar']
  if (className) {
    classNameList.push(className)
  }

  const parentLink = linkUrl ? (
    <Link
      className='header-navbar-link'
      path={linkUrl}
      onClick={onLinkClick}
    >
      {linkLabel}
    </Link>
  ) : <span className='header-navbar-text'>{linkLabel}</span>

  return (
    <HeaderContainer>
      <div className={classNameList.join(' ')}>
        <div className='navbar-left'>
          <Logo logoSrc={logoSrc} />
          <div className='navbar-group'>
            {parentLink}
            <span className='header-navbar-link'>&rsaquo;</span>
            <span className='header-navbar-text'>{fullName}</span>
          </div>
        </div>
        <div className='navbar-right'>
          <AccountDropdown
            accountImgSrc={accountImgSrc}
            onLogout={onLogout}
          />
        </div>
      </div>
    </HeaderContainer>
  )
}

PersonHeader.propTypes = {
  /** for example: `header-navbar-fixed` */
  className: PropTypes.string,
  onLinkClick: PropTypes.func,
  linkUrl: PropTypes.string,
  linkLabel: PropTypes.string,
  fullName: PropTypes.string,
  onLogout: PropTypes.func,
  accountImgSrc: PropTypes.string,
  logoSrc: PropTypes.string
}

PersonHeader.defaultProps = {
  linkLabel: 'Facility Link',
  fullName: 'Selected Person'
}

export default PersonHeader
