import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

function Card (props) {
  const {
    holderClassName,
    children
  } = props

  let leftContent
  let rightContent
  if (Array.isArray(children)) {
    const len = children.length
    const index = Math.ceil(len / 2)
    leftContent = children.slice(0, index)
    rightContent = children.slice(index)
  } else {
    leftContent = children
  }

  const holderClassNameList = ['card']
  if (holderClassName) {
    holderClassNameList.push(holderClassName)
  }

  return (
    <div className={holderClassNameList.join(' ')}>
      <div className='card-left'>
        {leftContent}
      </div>
      <div className='card-right'>
        {rightContent}
      </div>
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.node,
  holderClassName: PropTypes.string
}

export default Card
