import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone' // https://react-dropzone.js.org/
import ElementHolder from '../ElementHolder'
import FileList from './FileList'

import './style.css'

/** Also all props of **ElementHolder** */
function DropZone (props) {
  const {
    name,
    data,
    children,
    onUpdate,
    maxFiles,
    value,
    acceptFileTypes,
    onError
  } = props
  const [files, setFiles] = useState(value)
  useEffect(() => {
    // need to unset previous uploaded files
    setFiles(value)
  }, [value])
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    setFiles(acceptedFiles)
    if (typeof onError === 'function') {
      onError(fileRejections)
    }
    if (typeof onUpdate === 'function') {
      onUpdate(acceptedFiles, name)
    }
  }, [data])

  const dropZoneProps = { onDrop, maxFiles }
  if (acceptFileTypes) {
    if (Array.isArray(acceptFileTypes)) {
      dropZoneProps.accept = acceptFileTypes.join(', ')
    } else {
      dropZoneProps.accept = acceptFileTypes
    }
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone(dropZoneProps)

  let defaultMessage
  let draggingMessage
  if (Array.isArray(children) && children.length >= 2) {
    defaultMessage = children[0]
    draggingMessage = children[1]
  } else {
    defaultMessage = children
    draggingMessage = children
  }
  let content
  if (files.length) {
    const remove = (ev, fileIndex) => {
      ev.stopPropagation()
      const newFiles = files.filter((f, i) => i !== fileIndex)
      setFiles(newFiles)
      if (typeof onUpdate === 'function') {
        onUpdate(newFiles, name)
      }
    }
    content = (
      <FileList
        {...props}
        files={files}
        oneFileOnly={maxFiles === 1}
        onRemove={remove}
      />
    )
  } else if (isDragActive) {
    content = draggingMessage
  } else {
    content = defaultMessage
  }

  return (
    <ElementHolder {...props}>
      <div className='drop-zone-ui'>
        <div
          className='drop-zone-holder flex-center vertical'
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {content}
        </div>
      </div>
    </ElementHolder>
  )
}

DropZone.propTypes = {
  name: PropTypes.string,
  data: PropTypes.object,
  /** React Class, pass to FileList */
  FileItem: PropTypes.func,
  /** React Class, pass to FileList */
  RejectionItem: PropTypes.func,
  /** React Class */
  RejectionItem: PropTypes.func,
  /** children[0]:  defaultMessage, children[1]: draggingMessage */
  children: PropTypes.node,
  onUpdate: PropTypes.func,
  maxFiles: PropTypes.number,
  /** File List Object */
  value: PropTypes.any,
  acceptFileTypes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  onError: PropTypes.func
}

DropZone.defaultProps = {
  children: [
    'Drag \'n\' drop some files here, or click to select files',
    'Drop the files here ...'
  ],
  value: [],
  onUpdate: files => console.log('Files', files)
}

export default DropZone
