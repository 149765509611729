import React from 'react'
import CheckboxGroup from '../../formElements/CheckboxGroup'
import DatePicker from '../../formElements/DatePicker'
import Dropdown from '../../formElements/Dropdown'
import DropZone from '../../formElements/DropZone'
import RadioGroup from '../../formElements/RadioGroup'
import Address from '../../advancedFormElements/Address'
import ElementGroup from '../../advancedFormElements/ElementGroup'
import DropdownWithTextInput from '../../advancedFormElements/DropdownWithTextInput'
import List from '../../advancedFormElements/List'
import RadioGroupWithTextInput from '../../advancedFormElements/RadioGroupWithTextInput'
import TextInput from '../../formElements/TextInput'
import GroupGenerator from '../GroupGenerator'

function Label (props) {
  const { label, className } = props
  if (label) {
    const classNameList = ['group-title']
    if (className) {
      classNameList.push(className)
    }

    return (
      <h3 className={classNameList.join(' ')}>{label}</h3>
    )
  }

  return false
}

function Content (props) {
  const { content, contentType } = props
  if (contentType === 'dom') {
    let htmlContent = content
    if (Array.isArray(content)) {
      htmlContent = content.join('')
    }

    return (
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    )
  }

  return content
}

export const DEFAULT_UI_COMPONENTS_MAP = {
  Address,
  CheckboxGroup,
  Content,
  DatePicker,
  Dropdown,
  DropZone,
  DropdownWithTextInput,
  ElementGroup,
  GroupGenerator,
  Label,
  RadioGroup,
  RadioGroupWithTextInput,
  TextInput,
  List
}

const RADIO_GROUP_TYPE = [
  'buttons',
  'boolean_buttons',
  'radio',
  'boolean_radio',
  'checkbox'
]

const RADIO_GROUP_WITH_DETAILS_TYPE = [
  'buttons_with_details',
  'boolean_buttons_with_details',
  'radio_with_details',
  'boolean_radio_with_details'
]

export function DEFAULT_MAP_COMPONENT_NAME (c, extraMapFunc) {
  const { type } = c
  if (typeof extraMapFunc === 'function') {
    const name = extraMapFunc(c)
    if (name) {
      return name
    }
  }

  if (RADIO_GROUP_TYPE.includes(type)) {
    return 'RadioGroup'
  } else if (RADIO_GROUP_WITH_DETAILS_TYPE.includes(type)) {
    return 'RadioGroupWithTextInput'
  } else if (type === 'label') {
    return 'Label'
  } else if (type === 'datepicker') {
    return 'DatePicker'
  } else if (type === 'group') {
    return 'GroupGenerator'
  } else if (type === 'dropdown') {
    return 'Dropdown'
  } else if (type === 'dropdown_with_details') {
    return 'DropdownWithTextInput'
  } else if (type === 'list') {
    return 'List'
  } else if (type === 'dropzone') {
    return 'DropZone'
  } else if (type === 'address') {
    return 'Address'
  } else if (type === 'content') {
    return 'Content'
  } else if (type === 'elementGroup') {
    return 'ElementGroup'
  }

  return 'TextInput'
}
