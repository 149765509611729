import React from 'react'
import PropTypes from 'prop-types'
import EditTablePopup, { TYPE_MOVE, TYPE_REMOVE } from '../../popups/EditTablePopup'

function EditPopup (props) {
  const { data, onSave } = props
  const popupProps = {
    title: 'Edit existing pages',
    modalClassName: 'modal-lg',
    trigger: 'Edit existing pages',
    triggerClassName: 'btn-sm',
    onSave: d => onSave(d)
  }
  const tableBuilderProps = {
    keyField: 'menuTitle',
    data
  }
  const columnMetadata = [
    {
      dataField: 'pageTitle',
      text: 'Page title',
      headerAlign: 'center'
    },
    {
      dataField: 'menuTitle',
      text: 'Menu title',
      headerAlign: 'center'
    },
    {
      dataField: 'content',
      text: 'Content',
      headerAlign: 'center'
    },
    {
      dataField: 'move',
      text: 'move',
      isDummyField: true,
      type: TYPE_MOVE,
      headerAlign: 'center'
    },
    {
      dataField: 'remove',
      text: 'remove',
      isDummyField: true,
      type: TYPE_REMOVE,
      align: 'center',
      headerAlign: 'center'
    }
  ]

  return (
    <EditTablePopup
      columnMetadata={columnMetadata}
      popupProps={popupProps}
      tableBuilderProps={tableBuilderProps}
      addBtnLabel='Add new page'
      defaultNewRow={{
        menuTitle: 'New page',
        content: 'New page content'
      }}
    />
  )
}

EditPopup.propTypes = {
  data: PropTypes.array,
  onSave: PropTypes.func.isRequired
}

export default EditPopup
