import React from 'react'
import PropTypes from 'prop-types'
import Relation, { RELATION_TYPE_DISABILITY } from '../Relation'
import Dropdown from '../../formElements/Dropdown'
import TextInput from '../../formElements/TextInput'

import './style.css'

function DropdownWithTextInput (props) {
  const {
    className,
    dropdownProps,
    textInputProps,
    reverseUiComponent
  } = props
  const classNameList = ['dropdown-relation']
  if (className) {
    classNameList.push(className)
  }
  if (reverseUiComponent) {
    classNameList.push('reverse')
  }

  return (
    <Relation
      {...props}
      className={classNameList.join(' ')}
      trigger={reverseUiComponent ? TextInput : Dropdown}
      triggerProps={reverseUiComponent ? textInputProps : dropdownProps}
      projection={reverseUiComponent ? Dropdown : TextInput}
      projectionProps={reverseUiComponent ? dropdownProps : textInputProps}
      relationType={RELATION_TYPE_DISABILITY}
    />
  )
}

DropdownWithTextInput.propTypes = {
  className: PropTypes.string,
  holderClassName: PropTypes.string,
  data: PropTypes.object,
  /** See Dropdown's "PROPS & METHODS" */
  dropdownProps: PropTypes.object,
  /** See TextInput's "PROPS & METHODS" */
  textInputProps: PropTypes.object,
  mapValue: PropTypes.func,
  onUdate: PropTypes.func,
  visibility: PropTypes.bool,
  reverseUiComponent: PropTypes.bool
}

export default DropdownWithTextInput
