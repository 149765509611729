// https://github.com/augusthealth/august-backend/blob/master/loquat/modules/protos/src/main/protobuf/com/august/protos/health_questionnaire.proto#L85-L92
export const SEVERITY_OPTIONS = [
  {
    label: 'No',
    value: 'SEVERITY_ABSENT'
  },
  {
    label: 'Mild',
    value: 'SEVERITY_MILD'
  },
  {
    label: 'Moderate',
    value: 'SEVERITY_MODERATE'
  },
  {
    label: 'Severe',
    value: 'SEVERITY_SEVERE'
  }
]

export const BOOLEAN_OPTIONS = [
  { label: 'YES', value: true },
  { label: 'NO', value: false }
]
