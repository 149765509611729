import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

export default function Logo (props) {
  const { logoSrc } = props
  const content = logoSrc ? (
    <img className='logo-image' src={logoSrc} />
  ) : (
    <Fragment>
      <strong>august</strong> health
    </Fragment>
  )

  return (
    <div className='navbar-left-logo'>
      {content}
    </div>
  )
}

Logo.propTypes = {
  logoSrc: PropTypes.string
}
