import { get, isEmpty } from '../../../utils'

export default function getInvalidFields ({
  data,
  requiredFields = [],
  defaultRequiredMessage = '* Required',
  requiredMessageMap = {}
}) {
  const invalidFieldsMap = {}
  requiredFields.forEach(rf => {
    if (Array.isArray(rf)) {
      if (rf.every(f => isEmpty(get(data, f), [false]))) {
        rf.forEach(f => {
          invalidFieldsMap[f] = requiredMessageMap[f] || defaultRequiredMessage
        })
      }
    } else if (typeof rf === 'object') {
      const { path, isInvalidFunc } = rf
      if (path && typeof isInvalidFunc === 'function') {
        const invalidMessage = isInvalidFunc(get(data, path))
        if (invalidMessage) {
          if (typeof invalidMessage === 'string') {
            invalidFieldsMap[path] = invalidMessage
          } else {
            invalidFieldsMap[path] = requiredMessageMap[path] || defaultRequiredMessage
          }
        }
      }
    } else if (isEmpty(get(data, rf), [false])) {
      invalidFieldsMap[rf] = requiredMessageMap[rf] || defaultRequiredMessage
    }
  })

  return invalidFieldsMap
}
