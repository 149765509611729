import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ElementHolder from '../ElementHolder'

export const TEXT_INPUT_TYPE_NUMBER = 'number'
export const TEXT_INPUT_TYPE_PASSWORD = 'password'
export const TEXT_INPUT_TYPE_SEARCH = 'search'
export const TEXT_INPUT_TYPE_TEXT = 'text'
export const TEXT_INPUT_TYPE_TEXTAREA = 'textarea'
export const TEXT_INPUT_TYPES = [
  TEXT_INPUT_TYPE_NUMBER,
  TEXT_INPUT_TYPE_PASSWORD,
  TEXT_INPUT_TYPE_SEARCH,
  TEXT_INPUT_TYPE_TEXT,
  TEXT_INPUT_TYPE_TEXTAREA
]

/** Also all props of **ElementHolder** */
function TextInput (props) {
  const {
    name,
    type,
    value,
    readOnly,
    disabled,
    onUpdate,
    onChange,
    className,
    placeholder,
    visibility,
    tabIndex,
    rows,
    isRequired
  } = props
  const [inputValue, setInputValue] = useState(value)
  const [newValue, setNewValue] = useState(value)

  useEffect(() => {
    setInputValue(value)
    setNewValue(value)
  }, [value])

  if (visibility === false) {
    return false
  }

  const classNameList = ['form-control']
  if (className) {
    classNameList.push(className)
  }
  if (disabled) {
    classNameList.push('disabled')
  }
  const classN = classNameList.join(' ')

  function update (value) {
    if (value !== newValue) {
      const updatedValue = value ? value : undefined
      if (isRequired && updatedValue === undefined) {
        setNewValue(newValue)
        setInputValue(newValue)
      } else {
        setNewValue(updatedValue)

        if (typeof onUpdate === 'function') {
          onUpdate(updatedValue, name)
        }
      }
    }
  }

  function change (value) {
    setInputValue(value)

    if (typeof onChange === 'function') {
      onChange(value, name)
    }
  }

  let component
  if (readOnly) {
    component = (<div>{value}</div>)
  } else if (type === 'textarea') {
    component = (
      <textarea
        className={classN}
        name={name}
        value={inputValue}
        onBlur={ev => { update(ev.target.value)}}
        onKeyUp={ev => { ev.keyCode === 13 && update(ev.target.value)}}
        onChange={ev => { change(ev.target.value)}}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete='off'
        autoCorrect='off'
        tabIndex={tabIndex}
        rows={rows}
      />
    )
  } else {
    component = (
      <input
        className={classN}
        type={type}
        name={name}
        value={inputValue}
        onBlur={ev => { update(ev.target.value)}}
        onKeyUp={ev => { ev.keyCode === 13 && update(ev.target.value)}}
        onChange={ev => { change(ev.target.value)}}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete='off'
        autoCorrect='off'
        tabIndex={tabIndex}
      />
    )
  }

  return (
    <ElementHolder
      {...props}
      value={newValue}
    >
      {component}
    </ElementHolder>
  )
}

TextInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  type: PropTypes.oneOf(TEXT_INPUT_TYPES),
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onUpdate: PropTypes.func,
  placeholder: PropTypes.string,
  visibility: PropTypes.bool,
  tabIndex: PropTypes.number,
  rows: PropTypes.number,
  isRequired: PropTypes.bool
}

TextInput.defaultProps = {
  value: '',
  type: 'text',
  visibility: true,
  onUpdate: (value, name) => {
    console.log('TextInput', 'onUpdate', 'value', value, 'name', name)
  }
}

export default TextInput
