/** PENDING */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import ProtoBuf from 'protobufjs';

function ProtobufConverter (props) {
  const [error, setError] = useState(undefined);
  const [json, setJson] = useState(undefined);
  const { url, onLoad } = props;

  useEffect(() => {
    if (json === undefined && error === undefined) {
      ProtoBuf.load(url)
        .then(root => {
          onLoad(root);
          setJson(root);
        })
        .catch(err => {
          setError(err);
        });
    }
  }, [error, json, url]);

  let content;
  if (error) {
    content = (
      <div className='text-danger'>
        {error.toString()}
      </div>
    );
  } else {
    content = (
      <pre>
        {JSON.stringify(json, null, 2)}
      </pre>
    );
  }

  return (
    <div>
      {content}
    </div>
  );
}

ProtobufConverter.propTypes = {
  url: PropTypes.string,
  onLoad: PropTypes.func
}

export default ProtobufConverter
