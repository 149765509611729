import React from 'react'
import PropTypes from 'prop-types'
import ElementHolder from '../../formElements/ElementHolder'
import Dropdown from '../../formElements/Dropdown'
import TextInput from '../../formElements/TextInput'
import US_STATES from './data/usStates.json'
import { STANDARD_CONFIGURATION } from './config'
import { get } from '../../../utils'

const ADDRESS_FIELDS = [
  {
    className: 'address-line',
    name: 'line[0]',
    uiComp: TextInput
  },
  {
    className: 'address-line',
    name: 'line[1]',
    uiComp: TextInput
  },
  {
    className: 'address-city',
    name: 'city',
    uiComp: TextInput
  },
  {
    className: 'address-state btn-stroke btn-in-form',
    name: 'state',
    uiComp: Dropdown,
    options: US_STATES
  },
  {
    className: 'address-postal-code',
    name: 'postalCode',
    uiComp: TextInput
  }
]

/** Also all props of **ElementHolder** */
function Address (props) {
  const { name, configuration: defaultConf, onUpdate, value } = props
  const configuration = defaultConf === 'standard' ? STANDARD_CONFIGURATION : defaultConf

  const comps = ADDRESS_FIELDS.map((a, i) => {
    const { uiComp: UI, name: fieldName } = a
    const n = `${name}.${fieldName}`
    const conf = configuration[i] || {}

    return (
      <UI
        {...a}
        key={n}
        name={n}
        {...conf}
        onUpdate={onUpdate}
        value={get(value, fieldName)}
      />
    )
  })

  return (
    <ElementHolder {...props}>
      <div className='address-group'>
        {comps}
      </div>
    </ElementHolder>
  )
}

Address.propTypes = {
  name: PropTypes.string,
  /** "standard" or [...] */
  configuration: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        subTitle: PropTypes.string,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        className: PropTypes.string
      })
    ),
    PropTypes.string
  ]),
  value: PropTypes.shape({
    line: PropTypes.array,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string
  }),
  onUpdate: PropTypes.func
}

Address.defaultProps = {
  name: 'address',
  configuration: []
}

export default Address
