import React from 'react'
import PropTypes from 'prop-types'
import BootstrapTable from '../Table'

function TableBuilder (props) {
  const {
    keyField,
    columns,
    data,
    searchable,
    searchBarProps,
    tableProps,
    togglable,
    toggleBarProps
  } = props

  /**
   * TODO: Remove this component entirely.
   * This is a temporary step to make sure the main frontend app is working
   * with the dependency upgrades.
   */
  return null
}

TableBuilder.propTypes = {
  keyField: PropTypes.string,
  /** See Table's "PROPS & METHODS" */
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  /** See Table's "PROPS & METHODS" */
  tableProps: PropTypes.object,
  searchable: PropTypes.bool,
  searchBarProps: PropTypes.shape({
    className: PropTypes.string,
    delay: PropTypes.number,
    placeholder: PropTypes.string
  }),
  togglable: PropTypes.bool,
  toggleProps: PropTypes.shape({
    btnClassName: PropTypes.string,
    className: PropTypes.string,
    contextual: PropTypes.string
  })
}

TableBuilder.defaultProps = {
  keyField: 'id'
}

export default TableBuilder
