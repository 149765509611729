import React from 'react'
import PropTypes from 'prop-types'

import FormGenerator from '../FormGenerator'

function GroupGenerator (props) {
  const {
    configuration,
    data,
    sharedProps,
    onUpdate,
    mapComponentName,
    mapComponentProps,
    uiComponentsMap,
    mapExtraCompName,
    readOnly
  } = props
  const groups = configuration.map(c => {
    const {
      groupId,
      formGeneratorProps
    } = c;

    const update = (value, name) => {
      if (typeof onUpdate === 'function') {
        onUpdate(value, name)
      }
    }

    return (
      <FormGenerator
        key={groupId}
        data={data}
        readOnly={readOnly}
        {...sharedProps}
        {...formGeneratorProps}
        onUpdate={update}
        mapComponentName={mapComponentName}
        mapComponentProps={mapComponentProps}
        uiComponentsMap={uiComponentsMap}
        mapExtraCompName={mapExtraCompName}
      />
    )
  })

  return (
    <div className='group-generator form-goup'>
      {groups}
    </div>
  )
}

GroupGenerator.propTypes = {
  configuration: PropTypes.arrayOf(
    PropTypes.shape({
      groupId: PropTypes.string,
      /** See FormGenerator's "PROPS & METHODS" */
      formGeneratorProps: PropTypes.object
    })
  ),
  data: PropTypes.object,
  /** See FormGenerator's "PROPS & METHODS" */
  sharedProps: PropTypes.object,
  onUpdate: PropTypes.func,
  mapComponentName: PropTypes.func,
  mapExtraCompName: PropTypes.func,
  mapComponentProps: PropTypes.func,
  uiComponentsMap: PropTypes.object,
  readOnly: PropTypes.bool
}

GroupGenerator.defaultProps = {
  configuration: []
}

export default GroupGenerator
