import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Button'

import './style.css'

function ButtonGroup (props) {
  const { className, options } = props
  const divClassNameList = ['buttons-holder']
  if (className) {
    divClassNameList.push(className)
  }

  const buttons = options.map((opt, i) => {
    const {
      className,
      component,
      componentProps,
      label,
      disabled,
      onClick
    } = opt
    const key = `${label}-${i}`
    const Component = component || Button
    const btnClassNameList = []
    if (className) {
      btnClassNameList.push(className)
    }
    if (disabled) {
      btnClassNameList.push('disabled')
    }

    return (
      <Component
        {...componentProps}
        key={key}
        className={btnClassNameList.join(' ')}
        onClick={onClick}
      >
        {label}
      </Component>
    )
  })

  return (
    <div className={divClassNameList.join(' ')}>
      {buttons}
    </div>
  )
}

ButtonGroup.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
      ]),
      className: PropTypes.string,
      component: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node
      ]),
      componentProps: PropTypes.object,
      data: PropTypes.any,
      disabled: PropTypes.bool,
      onClick: PropTypes.func
    })
  ).isRequired
}

export default ButtonGroup
