import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ElementHolder from '../ElementHolder'
import Button from '../../generic/Button'
import { isEmpty } from '../../../utils'

import './style.css'

const OPTION_SEPARATOR = 'separator'

/** Also all props of **ElementHolder** */
function Dropdown (props) {
  const {
    className,
    name,
    itemComponent: Item,
    labelComponent: Label,
    options,
    readOnly,
    value,
    onUpdate,
    children: Menu,
    openOnHover,
    placeholder,
    dropdownClassName,
    filterOutSelectedOption,
    visibility,
    isRequired,
    disabled
  } = props
  const [open, setOpen] = useState(false)
  const findSelectedOption = o => {
    return (
      o !== OPTION_SEPARATOR &&
      (
        o.value === value ||
        (Array.isArray(value) && value.includes(o.value))
      )
    )
  }
  const [selectedOption, setSelectedOption] = useState(options.find(findSelectedOption))
  const dropdownRef = React.createRef()
  const onBodyClick = ev => {
    if (open) {
      let parent = ev.target.parentNode
      // Stop search when Parent is null, Dropdown DOM element or Body
      while (parent && parent !== dropdownRef.current && parent !== document.body) {
        parent = parent.parentNode
      }

      if (parent && parent !== dropdownRef.current) {
        // Collapse Dropdown Menu when click outside of Dropdown
        setOpen(false)
      }
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', onBodyClick)

    return () => {
      document.body.removeEventListener('click', onBodyClick)
    }
  })
  useEffect(() => {
    setSelectedOption(options.find(findSelectedOption))
  }, [value, options])

  if (visibility === false) {
    return false
  }

  const classNameList = ['btn']
  if (!disabled) {
    classNameList.push('dropdown-toggle')
  }
  if (className) {
    classNameList.push(className)
  }
  let label = ''
  if (!isEmpty(selectedOption)) {
    if (Label) {
      label = (
        <Label
          {...selectedOption}
          isLabel
        />
      )
    } else {
      label = selectedOption.label || selectedOption.value
    }
  } else if (!readOnly) {
    if (Label) {
      label = (
        <Label
          label={placeholder}
          isLabel
        />
      )
    } else {
      label = placeholder
    }
    classNameList.push('placeholder')
  }

  if (disabled) {
    return (
      <Button
        className={classNameList.join(' ')}
        disabled
      >
        {label}
      </Button>
    )
  }

  let opts = [...options]
  if (selectedOption && filterOutSelectedOption) {
    opts = opts.filter(o => {
      return (
        o === OPTION_SEPARATOR
        || o.value !== selectedOption.value
        || o.label !== selectedOption.label
      )
    })

    if (placeholder && !isRequired) {
      opts.unshift({
        label: placeholder,
        className: 'placeholder'
      })
    }
  }

  const list = opts.map((o, i) => {
    if (o === OPTION_SEPARATOR) {
      return (
        <hr
          key={`dropdown-separator-${i}`}
          className='dropdown-hr'
        />
      )
    }

    const { className: optionClassName, data, label: l, value, onClick } = o
    const optionLabel = l || value
    const key = `${name}-${value}-${i}`
    const onItemClick = ev => {
      ev.preventDefault()
      let ignoreDefault = false
      if (typeof onClick === 'function') {
        ignoreDefault = onClick(value, name, data)
      } else if (typeof onUpdate === 'function') {
        ignoreDefault = onUpdate(value, name, data)
      }

      if (!ignoreDefault) {
        // Set value to undefined when equal { label: 'Select' }
        if (o.label === placeholder && o.value === undefined) {
          setSelectedOption(null)
        } else {
          setSelectedOption(o)
        }
      }

      setOpen(false)
    }
    const isSelected = value === (selectedOption && selectedOption.value)

    if (typeof Item === 'function') {
      return (
        <Item
          key={key}
          className={optionClassName}
          data={data}
          label={optionLabel}
          value={value}
          onClick={onItemClick}
          readOnly={readOnly}
          isSelected={isSelected}
        />
      )
    } else if (Item) { // node, to test, include CSS class .dropdown-item?
      return (
        <div
          key={key}
          className={optionClassName}
          onClick={onItemClick}
        >
          {Item}
        </div>
      )
    }

    const optionClassNameList = ['dropdown-item']
    if (optionClassName) {
      optionClassNameList.push(optionClassName)
    }
    return (
      <div
        key={key}
        className={optionClassNameList.join(' ')}
        onClick={onItemClick}
      >
        {isSelected ? (<i className='fa fa-check selected-icon' />) : undefined}
        {optionLabel}
      </div>
    )
  })

  /** To Do: separate Dropdown to Dropdown and DropdownWithList */
  const button = Menu ? (
    <Menu
      {...(opts[0] || {})}
      name={name}
      onClick={() => setOpen(!open)}
    />
  ) : (
    <button
      className={classNameList.join(' ')}
      type='button'
      name={name}
      data-toggle='dropdown'
      aria-haspopup='true'
      aria-expanded='false'
      onClick={() => setOpen(!open)}
    >
      {label}
    </button>
  )

  const dropdownClassNameList = open ? ['dropdown', 'show'] : ['dropdown']
  if (dropdownClassName) {
    dropdownClassNameList.push(dropdownClassName)
  }
  const dropdownMenuClass = open ? 'dropdown-menu show' : 'dropdown-menu'
  const dropdown = readOnly ? <div>{label}</div> : (
    <div
      ref={dropdownRef}
      className={dropdownClassNameList.join(' ')}
      onMouseEnter={openOnHover && (() => setOpen(!open))}
      onMouseLeave={openOnHover && (() => setOpen(!open))}
    >
      {button}
      <div className={dropdownMenuClass} aria-labelledby={name}>
        {list}
      </div>
    </div>
  )

  return (
    <ElementHolder
      {...props}
      childrenInReadOnlyHolder={readOnly}
    >
      {dropdown}
    </ElementHolder>
  )
}

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  itemComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ]),
  labelComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ]),
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      /** "separator" */
      PropTypes.string,
      PropTypes.shape({
        data: PropTypes.object,
        label: PropTypes.node,
        /** string, number or boolean */
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool
        ]),
        /** Allow to specify onClick for individual option, otherwise onUpdate will be called */
        onClick: PropTypes.func
      })
    ])
  ).isRequired,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool
  ]),
  onUpdate: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]),
  openOnHover: PropTypes.bool,
  /** Don't show placeholder when **placeholder** equals *false* */
  placeholder: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  dropdownClassName: PropTypes.string,
  filterOutSelectedOption: PropTypes.bool,
  visibility: PropTypes.bool,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool
}

Dropdown.defaultProps = {
  className: 'btn-stroke btn-in-form',
  onUpdate: (value, name) => console.log('onUpdate', value, name),
  placeholder: 'Select',
  visibility: true,
  options: [],
  value: null
}

export default Dropdown
