import React from 'react'
import PropTypes from 'prop-types'

function Bar (props) {
  const { width } = props

  return (
    <div className='progress'>
      <div
        className='progress-bar'
        role='progressbar'
        aria-valuenow='0'
        aria-valuemin='0'
        aria-valuemax='100'
        style={{ width }}
      />
    </div>
  )
}

Bar.propTypes = {
  onClick: PropTypes.func
}

export default Bar
