import React from 'react'
import PropTypes from 'prop-types'
import ElementHolder from '../../formElements/ElementHolder'
import formElements from '../../formElements'
import { get } from '../../../utils'

import './style.css'

/** Also all props of **ElementHolder** */
function ElementGroup (props) {
  const { className, data, elementPropsList } = props
  const elementProps = { ...props }
  // Remove ElementHolder attributes from elementProps
  Object.keys(ElementHolder.propTypes).forEach(k => {
    delete elementProps[k]
  })

  const elements = elementPropsList.reduce((list, el) => {
    const { elementName, name } = el
    const Comp = formElements[elementName]
    const value = get(data, name)
    if (Comp) {
      const key = `element-group-${name}`
      list.push(
        <Comp
          key={key}
          {...elementProps}
          {...el}
          value={value}
        />
      )
    }

    return list
  }, [])

  return (
    <ElementHolder {...props}>
      <div className={className}>
        {elements}
      </div>
    </ElementHolder>
  )
}

ElementGroup.propTypes = {
  className: PropTypes.string,
  elementPropsList: PropTypes.arrayOf(
    PropTypes.shape({
      elementName: PropTypes.string,
      name: PropTypes.string
    })
  )
}

ElementGroup.defaultProps = {
  className: 'horizontal',
  elementPropsList: []
}

export default ElementGroup
