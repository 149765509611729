import React from 'react'
import PropTypes from 'prop-types'
import Logo from '../Logo'
import AccountDropdown from '../AccountDropdown'
import HeaderContainer from '../HeaderContainer'

import '../style.css'

function LogoHeader (props) {
  const {
    className,
    onLogout,
    accountImgSrc,
    children,
    logoSrc
  } = props

  const classNameList = ['header-navbar']
  if (className) {
    classNameList.push(className)
  }

  return (
    <HeaderContainer>
      <div className={classNameList.join(' ')}>
        <div className='navbar-left'>
          <Logo logoSrc={logoSrc} />
          {children}
        </div>
        <div className='navbar-right'>
          <AccountDropdown
            accountImgSrc={accountImgSrc}
            onLogout={onLogout}
          />
        </div>
      </div>
    </HeaderContainer>
  )
}

LogoHeader.propTypes = {
  /** for example: `header-navbar-fixed` */
  className: PropTypes.string,
  onLogout: PropTypes.func,
  accountImgSrc: PropTypes.string,
  children: PropTypes.node
}

export default LogoHeader
