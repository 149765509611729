import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../generic/Button'

// Need to manually map File to a standard Object here
function fileToObject (data) {
  return {
    lastModified: data.lastModified,
    lastModifiedDate: data.lastModifiedDate,
    name: data.name,
    size: data.size,
    type: data.type
  }
}

function DefaultFileItem (file) {
  const { name, size, onRemove } = file
  return (
    <div className='upload-file-row horizontal'>
      <div><strong>{name}</strong> - {size} bytes</div>
      <Button className='btn-sm' onClick={onRemove}>Remove</Button>
    </div>
  )
}

function FileList (props) {
  const {
    FileItem,
    files,
    oneFileOnly,
    onRemove
  } = props

  if (files.length) {
    if (oneFileOnly) {
      return (
        <div className='upload-file-list'>
          <FileItem
            {...fileToObject(files[0])}
            fileIndex={0}
            onRemove={ev => onRemove && onRemove(ev, 0)}
          />
        </div>
      )
    } else {
      const liList = files.map((f, i) => {
        const { path: fPath } = f
        return (
          <li key={fPath}>
            <FileItem
              {...fileToObject(f)}
              fileIndex={i}
              onRemove={ev => onRemove && onRemove(ev, i)}
            />
          </li>
        )
      })
      return (<ol className='upload-file-list'>{liList}</ol>)
    }
  }

  return false
}

FileList.propTypes = {
  /** React class */
  FileItem: PropTypes.func,
  files: PropTypes.array,
  oneFileOnly: PropTypes.bool,
  /** onRemove(ev, fileIndex) */
  onCancel: PropTypes.func
}

FileList.defaultProps = {
  FileItem: DefaultFileItem,
  files: []
}

export default FileList
